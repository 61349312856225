/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BudgetDto } from './BudgetDto';
import type { CreateRequirementRequest } from './CreateRequirementRequest';
export type CreateProcessRequest = {
    hrProviderIds?: Array<number>;
    requirement: CreateRequirementRequest;
    stepIds?: Array<number>;
    note?: string;
    budget?: BudgetDto;
    deadline?: string;
    numberOfHires?: number;
    salaryMin?: number;
    salaryMax?: number;
    salaryCurrency?: CreateProcessRequest.salaryCurrency;
    salaryVisible?: boolean;
};
export namespace CreateProcessRequest {
    export enum salaryCurrency {
        DOLLAR = 'DOLLAR',
        EURO = 'EURO',
        TL = 'TL',
    }
}

