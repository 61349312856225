/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProcessQuestionAnswerDto = {
    id?: number;
    createdBy?: number;
    createdAt?: string;
    questionAnswer?: string;
    authorCompanyType?: ProcessQuestionAnswerDto.authorCompanyType;
};
export namespace ProcessQuestionAnswerDto {
    export enum authorCompanyType {
        EMPLOYER = 'EMPLOYER',
        HR_PROVIDER = 'HR_PROVIDER',
    }
}

