/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateSerpLinkJobRequest } from '../models/CreateSerpLinkJobRequest';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class SerpLinkControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public serpLinkSearch(
        requestBody: CreateSerpLinkJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/google/search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
