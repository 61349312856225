/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseListTeamDto } from '../models/AmsResponseListTeamDto';
import type { AmsResponseTeamDto } from '../models/AmsResponseTeamDto';
import type { CreateTeamRequest } from '../models/CreateTeamRequest';
import type { UpdateTeamRequest } from '../models/UpdateTeamRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class TeamControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @returns AmsResponseListTeamDto OK
     * @throws ApiError
     */
    public list(): Observable<AmsResponseListTeamDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/teams',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseTeamDto OK
     * @throws ApiError
     */
    public updateTeam(
        requestBody: UpdateTeamRequest,
    ): Observable<AmsResponseTeamDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/teams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseTeamDto OK
     * @throws ApiError
     */
    public createTeam(
        requestBody: CreateTeamRequest,
    ): Observable<AmsResponseTeamDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/teams',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns AmsResponseTeamDto OK
     * @throws ApiError
     */
    public getTeam(
        id: number,
    ): Observable<AmsResponseTeamDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/teams/{id}',
            path: {
                'id': id,
            },
        });
    }
}
