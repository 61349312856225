<div class="company-details">
    <div class="container-top">
        <div class="company-header-wrapper">
            <img [src]="company?.companyDto?.logoUrl" class="profile-photo" alt="logo" (error)="onImageError($event)" />
            <div>
                <h1>Job Title</h1>
                <p class="profile-title">
                    <a href="{{ company?.companyDto?.webSiteUrl }}">Description</a>
                </p>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="wrapper-user-info-left">
            <div>
                <h1>Job title</h1>
                <p>6 Java Developers <span class="ml-4 view-process">View Process</span></p>
                <hr />
            </div>

            <div class="work-history-container">
                <h1>Job Description</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Ac leo velit sed lacus mattis sagittis.
                    Vivamus eleifend turpis consequat convallis. Ipsum elementum sed lacus arcu aliquam porta.
                    Scelerisque sed dis at vestibulum. Egestas sed egestas faucibus amet etiam tellus eu mi eget. Amet
                    diam ac vel odio. Sit arcu ultrices tortor mattis eu. Ullamcorper felis augue adipiscing ullamcorper
                    vitae massa felis quam. Tellus congue dolor consequat non euismod feugiat sed. Est massa neque enim
                    vestibulum at amet consequat arcu tempus. Molestie in ultricies varius blandit diam morbi convallis
                    lacus. Ultrices tempor id sit facilisis tempus nulla massa ridiculus. Netus magna fermentum blandit
                    orci</p>
            </div>
        <app-job-ads-preview-candidate-table></app-job-ads-preview-candidate-table>

        </div>
        <div class="wrapper-line">
            <hr />
        </div>
        <div class="wrapper-user-info-right mt-7">
            <div class="justify-content">
                <p>Experience</p>
                <p class="text-bold">Experience</p>
            </div>
            <div class="justify-content">
                <p>Languages</p>
                <p class="text-bold">Languages</p>
            </div>
            <div class="justify-content">
                <p>Location</p>
                <p class="text-bold">Location</p>
            </div>
            <div class="justify-content">
                <p>Working Type</p>
                <p class="text-bold">Working Type</p>
            </div>
            <div class="justify-content">
                <p>Job Type</p>
                <p class="text-bold">Job Type</p>
            </div>
            <div class="justify-content">
                <p>Created</p>
                <p class="text-bold">12.10.2023</p>
            </div>
            <div style="margin-top: 30px;">
                <p>Required capabilites</p>
                <div style="display: flex;">
                    <p class="types">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-job-ads-process-dialog [display]="displaySteps"></app-job-ads-process-dialog>