/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FilterJobTraceRequest = {
    teammateIds?: Array<number>;
    source?: FilterJobTraceRequest.source;
    sourceType?: FilterJobTraceRequest.sourceType;
    fromDate?: string;
    toDate?: string;
    searchStatus?: Array<'STARTED' | 'SEARCHING' | 'PARSING' | 'WRITING' | 'IN_PROGRESS' | 'COMPLETED'>;
    keywordList?: Array<string>;
    countryList?: Array<string>;
};
export namespace FilterJobTraceRequest {
    export enum source {
        SERP_LINK = 'serp_link',
        UNIVERSAL = 'universal',
        DOWNLOAD = 'download',
        USER_UPLOAD = 'user_upload',
        LINKED_IN = 'linkedIn',
        METADATA = 'metadata',
        TRANSCRIPT = 'transcript',
    }
    export enum sourceType {
        GOOGLE = 'google',
        BING = 'bing',
        YOUTUBE = 'youtube',
        DAILYMOTION = 'dailymotion',
        HTML = 'html',
        PDF = 'pdf',
        TXT = 'txt',
        VIDEO = 'video',
        PROFILE = 'profile',
        POST = 'post',
        USER_ACCESS_HISTORY = 'user_access_history',
        COMPANY = 'company',
    }
}

