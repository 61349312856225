/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangeProcessStatusRequest = {
    processStatus?: ChangeProcessStatusRequest.processStatus;
};
export namespace ChangeProcessStatusRequest {
    export enum processStatus {
        CREATED = 'CREATED',
        PENDING = 'PENDING',
        IN_PROGRESS = 'IN_PROGRESS',
        IN_REVIEW = 'IN_REVIEW',
        SUCCEED = 'SUCCEED',
        CANCELLED = 'CANCELLED',
        TO_BE_APPROVED = 'TO_BE_APPROVED',
    }
}

