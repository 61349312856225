import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TalentHuntService {
  private jobId: string = '';
  private countryList: string = '';
  private keywordList: string = '';


  setParameters(jobId: string, countryList: string, keywordList: string): void {
    this.jobId = jobId;
    this.countryList = countryList;
    this.keywordList = keywordList;
  }

  getParameters(): { jobId: string; countryList: string; keywordList: string } {
    return {
      jobId: this.jobId,
      countryList: this.countryList,
      keywordList: this.keywordList,
    };
  }
}
