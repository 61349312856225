/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StarDto } from './StarDto';
export type CompanyDto = {
    id?: number;
    createdAt?: string;
    industry?: string;
    name?: string;
    type?: CompanyDto.type;
    address?: string;
    taxNumber?: string;
    phoneNumber?: string;
    size?: string;
    logoUrl?: string;
    webSiteUrl?: string;
    additional?: string;
    email?: string;
    companyStarsDto?: Array<StarDto>;
    meanStar?: number;
};
export namespace CompanyDto {
    export enum type {
        EMPLOYER = 'EMPLOYER',
        HR_PROVIDER = 'HR_PROVIDER',
    }
}

