import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-question-dialog',
  templateUrl: './question-dialog.component.html',
  styleUrls: ['./question-dialog.component.scss']
})
export class QuestionDialogComponent {
  public form: UntypedFormGroup;
  public characterCount: number = 0;
  public title: string = "Ask a question";
  public paraph: string = "Send your question to clarify details before proceeding with the offer.";

  constructor(private formBuilder: UntypedFormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.form = this.formBuilder.group({
      note: new UntypedFormControl('',[Validators.required]),
    });
    if(this.config.data){
      this.title = this.config.data.title;
      this.paraph = this.config.data.paraph; 
    }
  }

  incrementCount() {
    this.characterCount = this.form.value.note.length;
  }

  getClassObject(field: string) {
    return {
      'border-invalid': !this.form!.get(field)!.valid && this.form!.get(field)!.touched,
    };
  }

  save(){
    this.ref.close({ note: this.form.value.note});
  }

  closeDialog(){
    this.ref.close(false);
  }
}
