import { Component } from '@angular/core';

@Component({
  selector: 'app-job-ads-process-dialog',
  templateUrl: './job-ads-process-dialog.component.html',
  styleUrls: ['./job-ads-process-dialog.component.scss']
})
export class JobAdsProcessDialogComponent {
  displayDialog = false;
  steps = [{ value: null }, { value: null }, { value: null }]; 
  dropdownOptions = [
    { label: 'Step 1', value: 'Step 1' },
    { label: 'Step 2', value: 'Step 2' },
    { label: 'Step 3', value: 'Step 3' },
    { label: 'Step 4', value: 'Step 4' },
  ];

  addStep() {
    this.steps.push({ value: null });
  }

  removeStep(index: number) {
    this.steps.splice(index, 1);
  }

  saveProcess() {
    this.displayDialog = false;
  }
}
