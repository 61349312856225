/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Budget = {
    type?: Budget.type;
    amount?: number;
};
export namespace Budget {
    export enum type {
        DOLLAR = 'DOLLAR',
        EURO = 'EURO',
        TL = 'TL',
    }
}

