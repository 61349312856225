/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseEndpointDto } from '../models/AmsResponseEndpointDto';
import type { AmsResponseListEndpointDto } from '../models/AmsResponseListEndpointDto';
import type { AmsResponseString } from '../models/AmsResponseString';
import type { CreateEndpointRequest } from '../models/CreateEndpointRequest';
import type { UpdateEndpointPrivilegeRequest } from '../models/UpdateEndpointPrivilegeRequest';
import type { UpdateEndpointRequest } from '../models/UpdateEndpointRequest';
import type { UpdateEndpointRoleRequest } from '../models/UpdateEndpointRoleRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class EndpointControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param endpointId
     * @returns AmsResponseEndpointDto OK
     * @throws ApiError
     */
    public getEndpointById(
        endpointId: number,
    ): Observable<AmsResponseEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/endpoint/{endpointId}',
            path: {
                'endpointId': endpointId,
            },
        });
    }
    /**
     * @param endpointId
     * @param requestBody
     * @returns AmsResponseEndpointDto OK
     * @throws ApiError
     */
    public updateEndpoint(
        endpointId: number,
        requestBody: UpdateEndpointRequest,
    ): Observable<AmsResponseEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/endpoint/{endpointId}',
            path: {
                'endpointId': endpointId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param endpointId
     * @returns AmsResponseString OK
     * @throws ApiError
     */
    public deleteEndpoint(
        endpointId: number,
    ): Observable<AmsResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/endpoint/{endpointId}',
            path: {
                'endpointId': endpointId,
            },
        });
    }
    /**
     * @param endpointId
     * @param requestBody
     * @returns AmsResponseEndpointDto OK
     * @throws ApiError
     */
    public updateEndpointRoles(
        endpointId: number,
        requestBody: UpdateEndpointRoleRequest,
    ): Observable<AmsResponseEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/endpoint/updateRoles/{endpointId}',
            path: {
                'endpointId': endpointId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param endpointId
     * @param requestBody
     * @returns AmsResponseEndpointDto OK
     * @throws ApiError
     */
    public updateEndpointPrivileges(
        endpointId: number,
        requestBody: UpdateEndpointPrivilegeRequest,
    ): Observable<AmsResponseEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/manage/endpoint/updatePrivileges/{endpointId}',
            path: {
                'endpointId': endpointId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AmsResponseListEndpointDto OK
     * @throws ApiError
     */
    public getAllEndpoints(): Observable<AmsResponseListEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/endpoint',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseEndpointDto OK
     * @throws ApiError
     */
    public createEndpoint(
        requestBody: CreateEndpointRequest,
    ): Observable<AmsResponseEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/endpoint',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uri
     * @returns AmsResponseListEndpointDto OK
     * @throws ApiError
     */
    public getEndpointByUri(
        uri: string,
    ): Observable<AmsResponseListEndpointDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/manage/endpoint/uri',
            query: {
                'uri': uri,
            },
        });
    }
}
