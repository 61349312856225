/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UpdateRequirementRequest } from './UpdateRequirementRequest';
export type UpdateProcessRequest = {
    hrProviderIds?: Array<number>;
    requirement?: UpdateRequirementRequest;
    stepIds?: Array<number>;
    note?: string;
    deadline?: string;
    numberOfHires?: number;
    salaryMin?: number;
    salaryMax?: number;
    salaryCurrency?: UpdateProcessRequest.salaryCurrency;
    salaryVisible?: boolean;
};
export namespace UpdateProcessRequest {
    export enum salaryCurrency {
        DOLLAR = 'DOLLAR',
        EURO = 'EURO',
        TL = 'TL',
    }
}

