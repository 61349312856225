<div class="container-top">
    <div class="user-info">
        <div>
            <p>Job Ads</p>
            <span> Here you‘ll find a list of all job advertisements you have created. </span>
        </div>

        <button class="custom-button" routerLink='/job-ads/add'>Create Job Ad</button>

    </div>
</div>

<div class="table-container">
    <div class="card talent-wrapper">
        <div *ngIf="orgUsers.length == 0 ">
            <div class="text-center">
                <div class="image">
                    <img src="../assets/images/no-users.svg">
                </div>
                <div>
                    <h3>No users available</h3>
                    <p class="font-bold">Click "Invite people" to get started.</p>
                </div>
            </div>
        </div>
        <div *ngIf="orgUsers.length > 0">
            <div class="col-12 filter-wrapper">
                <div class="col-5">
                    <input class="search-input" pInputText placeholder="Search..." [(ngModel)]="searchText"
                        (keyup)="searchTeammates()" />
                </div>
            </div>

            <div>
                <p-table #dt [value]="users" [rows]="rows" [first]="first" [paginator]="true"
                    [globalFilterFields]="['name']" [rowHover]="true" dataKey="externalId"
                    [showCurrentPageReport]="true">
                    <ng-template pTemplate="header" class="header">
                        <tr class="header-row">
                            <td colspan="7">
                                <div class="header-content">
                            <th style="width: 4%"></th>
                            <th pColumn="user.name" class="pl-2" style="width: 16.12%;">Job Ad title</th>
                            <th pColumn="user.roles" style="width: 10%">
                                Location
                            </th>
                            <th pColumn="user.createdAt" style="width: 16%">Created by</th>

                            <th pColumn="user.memberCount" style="width: 12%">Deadline</th>
                            <th pColumn="user.memberCount" style="width: 21%">No. Of Applicants</th>
                            <th pColumn="user.memberCount" style="width: 10.5%">Status</th>
                            <th>Actions</th>
            </div>
            </td>
            </tr>
            </ng-template>

            <ng-template pTemplate="body" let-user let-expanded="expanded">
                <tr routerLink="/job-ads/preview/1" style="cursor: pointer;"> 
                    <td>
                        <div [pTooltip]="user?.name" tooltipPosition="bottom">
                            <div [ngClass]="{'ellipsis-description': !user.expanded}">
                                {{ user.email }}
                            </div>
                            <span *ngIf="hasEllipsis">...</span>

                        </div>
                    </td>


                    <td style="width: 10%">
                        <div [pTooltip]="user?.roles" tooltipPosition="bottom">
                            <div [ngClass]="{'ellipsis-description': !user.expanded}">
                                Bosnia
                            </div>
                        </div>
                    </td>

                    <td style="width: 16%;">
                        <div [pTooltip]="user?.roles" tooltipPosition="bottom">
                            <div [ngClass]="{'ellipsis-description': !user.expanded}">
                                Sanjin Omerovic
                            </div>
                        </div>
                    </td>

                    <td style="width: 16%;">
                        <div [pTooltip]="user?.roles" tooltipPosition="bottom">
                            <div [ngClass]="{'ellipsis-description': !user.expanded}">
                                12.11.2024
                            </div>
                        </div>
                    </td>
                    <td style="width: 16%;">
                        <div [pTooltip]="user?.roles" tooltipPosition="bottom">
                            <div [ngClass]="{'ellipsis-description': !user.expanded}">
                                15
                            </div>
                        </div>
                    </td>
                    <td style="width: 12%;">
                        <div class="status text-center" [ngClass]="{
                  'pending':  user.status === 'Pending',
                  'active':  user.status === 'Active',
                  'deactivated':  user.status === 'Deactivated'
                }">
                            <div class="status-text">Active</div>
                        </div>
                    </td>

                    <td style="width: 10%;">
                        <div class="dropdown" style="display: inline-flex;">
                            <p pButton pRipple icon="pi pi-pencil" class="process-button edit-button"><p>
                            <p class="remove" (click)="openDialog('Deactivate', user)">Close</p>
                        </div>
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </div>
        <div *ngIf="users?.length === 0  && searchText.length > 0 ">
            <div class="text-center font-bold">No results found for "{{ searchText }}". Please try a different query.
            </div>
        </div>
    </div>
</div>
</div>