import { Component, OnInit } from '@angular/core';
import {
  CompanyControllerService,
  CompanyDto,
  CreateHRCompanyFilterRequest,
  DSResponseListCompanyDto,
  ProcessControllerService,
  UpdateProcessRequest,
} from '../../../oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from '../outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hr-providers-list',
  templateUrl: './hr-providers-list.component.html',
  styleUrls: ['./hr-providers-list.component.scss'],
})
export class HrProvidersListComponent implements OnInit {
  public providers: any[] = [];
  public first: number = 0;
  public rows: number = 8;
  public providersCount: number = 0;
  public pagedItems: any[] = [];
  public selectedProviders: CompanyDto[] = [];
  public searchText: string = '';
  public totalRecords: number = 0;
  public sentRequests: number[] = [];
  public selectedSort: { label: string; value: number; placeholder: string } = {
    label: 'Recently joined',
    value: 1,
    placeholder: 'newest',
  };
  public rating = [
    { label: 'Recently joined', value: 1, placeholder: 'newest' },
    { label: 'Best Rating', value: 2, placeholder: 'rating' },
  ];
  public request: any = null;
  public isLoading: boolean = true;
  public outgoingReqId: number = 0;
  public form: any = null;

  constructor(
    private companyService: CompanyControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private processControllerService: ProcessControllerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.request = navigation.extras.state['request'];
      this.form = navigation.extras.state['form'];
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      if (Number.isNaN(id)) {
        this.outgoingReqId = 0;
        this.getHrProviders();
      } else {
        this.outgoingReqId = id;
        this.getHrProvidersAndProcessInfo(this.outgoingReqId);
      }
    });
  }

  backToRequest() {
    this.router.navigate(['/outgoing-requests-upsert'], {
      state: { form: this.form },
    });
  }

  getHrProvidersAndProcessInfo(id: number) {
    this.processControllerService.getById1(id).subscribe({
      next: (response) => {
        const process = response?.body;

        const sentProviderIds =
          (process?.hrProviders
            ?.map((provider) => provider.id)
            .filter((id) => id !== undefined) as number[]) || [];

        this.getHrProviders(sentProviderIds);
      },
      error: (error) => {
        console.error('Error fetching process:', error);
        this.isLoading = false;
      },
    });
  }

  getHrProviders(sentProviderIds: number[] = []) {
    const requestBody: CreateHRCompanyFilterRequest = {};
    this.companyService.filter(requestBody).subscribe(
      (response) => {
        this.providers = response
          .body!.filter((provider) => provider.id !== undefined)
          .map((provider) => {
            return {
              ...provider,
              isRequestSent: sentProviderIds.includes(provider.id as number),
            };
          });

        this.updatePagedItems(0, 8);
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching data', error);
        this.isLoading = false;
      }
    );
  }

  paginate(event: any) {
    const startIndex = event.first;
    const endIndex = startIndex + event.rows;
    this.updatePagedItems(startIndex, endIndex);
  }

  update(selectedSort: any) {
    this.selectedSort = selectedSort;
    this.applyFilter();
  }

  updatePagedItems(startIndex: number, endIndex: number) {
    const filteredProviders = this.providers.filter(
      (provider: { name: string }) =>
        provider.name
          ?.toLocaleLowerCase()
          .includes(this.searchText.toLocaleLowerCase())
    );

    this.totalRecords = filteredProviders.length;
    this.pagedItems = filteredProviders
      .slice(startIndex, endIndex)
      .map((provider: { id: number | undefined }) => ({
        ...provider,
        isSelected: this.selectedProviders.some(
          (selected) => selected.id === provider.id
        ),
      }));
  }

  applyFilter() {
    const request: any = {
      ...(this.selectedSort && { sortBy: this.selectedSort.placeholder }),
    };

    this.filterOutProviders(request).subscribe((response) => {
      const resp = response.body;
      this.updateProviderList(resp);
    });
  }

  filterOutProviders(
    requestBody: CreateHRCompanyFilterRequest
  ): Observable<DSResponseListCompanyDto> {
    return this.companyService.filter(requestBody);
  }

  updateProviderList(providers: any) {
    const sentProviderIds = this.providers
      .filter((provider: { isRequestSent: any }) => provider.isRequestSent)
      .map((provider: { id: any }) => provider.id);

    this.providers = providers.map((provider: { id: number }) => ({
      ...provider,
      isRequestSent: sentProviderIds.includes(provider.id),
      isSelected: this.selectedProviders.some(
        (selected) => selected.id === provider.id
      ),
    }));

    this.updatePagedItems(0, 8);
  }

  searchProviders() {
    this.updatePagedItems(0, 8);
  }

  addSelectedProvider(event: any) {
    const index = this.selectedProviders.findIndex(
      (provider) => provider.id === event.id
    );
    if (index !== -1) {
      this.selectedProviders = this.selectedProviders.filter(
        (provider) => provider.id !== event.id
      );
      this.providers.find(f=>f.id == event.id).checked = false;
    } else {
      this.selectedProviders.push(event);
      this.providers.find(f=>f.id == event.id).checked = true;
    }
  }

  receiveFilters(event: any) {
    const sentProviderIds = this.providers
      .filter((provider: { isRequestSent: boolean }) => provider.isRequestSent)
      .map((provider: { id: number }) => provider.id);

    this.providers = event.resp.map((provider: any) => {
      return {
        ...provider,
        isRequestSent: sentProviderIds.includes(provider.id),
      };
    });

    this.updatePagedItems(0, 8);
  }

  sendRequestToSelectedHRs() {
    const hrProviderIds = this.selectedProviders
      .map((provider) => provider.id)
      .filter((id): id is number => id !== undefined);

    if (hrProviderIds.length === 0) {
      return;
    }

    const openConfirmDialog = (request: any) => {
      const ref = this.dialogService.open(
        ConfirmDialogOutgoingRequestComponent,
        {
          data: {
            request,
            providers: this.selectedProviders,
            assignToHrProvider: true,
            requestId: this.outgoingReqId || undefined,
          },
        }
      );

      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

      ref.onClose.subscribe((processId) => {
        if (this.outgoingReqId == 0) this.outgoingReqId = processId;
        this.router.navigate([
          `outgoing-request-details/${this.outgoingReqId}`,
        ]);
      });
    };

    if (this.request) {
      this.request.hrProviderIds = hrProviderIds;
      openConfirmDialog(this.request);
    } else if (this.outgoingReqId !== 0 && this.providers.length > 0) {
      const request = { hrProviderIds } as UpdateProcessRequest;
      openConfirmDialog(request);
    } else {
      this.router.navigate(['/outgoing-requests'], {
        state: { providers: this.selectedProviders },
      });
    }
  }
}
