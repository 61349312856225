import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { forbiddenEmailDomainValidator } from 'src/app/helpers/email-domain';
import {
  CreateInviteUserRequest,
  ManageUserControllerService,
  RegisterControllerService,
  UserControllerService,
} from 'src/app/oapi_client/data_symphony';

type Privileges = {
  talentAcquisition: boolean;
  talentHuntDiscovery: boolean;
  candidatePools: boolean;
  other: boolean;
  processManagement: boolean;
  teamManagement: boolean;
  jobAdvertisement: boolean;
};

@Component({
  selector: 'app-invite-member-modal',
  templateUrl: './invite-member-modal.component.html',
  styleUrls: ['./invite-member-modal.component.scss'],
})
export class InviteMemberModalComponent {
  constructor(
    private registerService: RegisterControllerService,
    private manageUserService: ManageUserControllerService,
    private userService: UserControllerService,
    private messageService: MessageService
  ) {}
  display: boolean = false;
  emails: string[] = [];
  invitedUsers: any[] = [];
  emailError: string = '';
  errorMessage: string = '';

  privileges: Privileges = {
    talentAcquisition: false,
    talentHuntDiscovery: false,
    candidatePools: false,
    other: false,
    processManagement: false,
    teamManagement: false,
    jobAdvertisement: false,
  };

  privilegeLabels = {
    talentAcquisition: 'Talent acquisition',
    talentHuntDiscovery: 'Talent hunt & Talent discovery',
    candidatePools: 'Candidate pools',
    other: 'Other',
    processManagement: 'Process management',
    teamManagement: 'Team management',
    jobAdvertisement: 'Job advertisement management',
  };

  showTooltip: boolean = false;

  validateEmail(event: any) {
    const email = event.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      this.emailError = 'Invalid email format';
      this.emails = this.emails.filter((e) => e !== email);
    } else {
      this.emailError = '';
    }

    const forbiddenDomainValidator = forbiddenEmailDomainValidator('gmail.com');
    const validationError = forbiddenDomainValidator({
      value: email,
    } as AbstractControl);

    if (validationError?.['forbiddenDomain']) {
      this.emailError = 'Gmail addresses are not allowed.';
      this.emails = this.emails.filter((e) => e !== email);
    }
  }

  removeEmail(event: any) {
    this.emails = this.emails.filter((email) => email !== event.value);
    this.emailError = '';
  }

  updatePrivileges() {
    if (this.privileges.processManagement) {
      this.privileges.talentAcquisition = true;
      this.privileges.talentHuntDiscovery = true;
      this.privileges.candidatePools = true;
    } else {
      this.privileges.talentAcquisition = false;
      this.privileges.talentHuntDiscovery = false;
      this.privileges.candidatePools = false;
    }
  }

  selectTalentAcquisition() {
    if (this.privileges.talentAcquisition) {
      this.privileges.talentHuntDiscovery = true;
      this.privileges.candidatePools = true;
    } else {
      this.privileges.talentHuntDiscovery = false;
      this.privileges.candidatePools = false;
    }
  }

  selectChildPrivileges() {
    if (this.privileges.talentHuntDiscovery && this.privileges.candidatePools) {
      this.privileges.talentAcquisition = true;
    } else {
      this.privileges.talentAcquisition = false;
    }
  }

  selectOther() {
    if (this.privileges.other) {
      this.privileges.processManagement = true;
      this.privileges.teamManagement = true;
      this.privileges.jobAdvertisement = true;
    } else {
      this.privileges.processManagement = false;
      this.privileges.teamManagement = false;
      this.privileges.jobAdvertisement = false;
    }
  }

  invite() {
    this.emails.forEach((email) => {
      let displayPrivileges: string[] = [];

      if (this.privileges.talentAcquisition) {
        displayPrivileges.push('Talent acquisition (all)');
      } else {
        if (this.privileges.talentHuntDiscovery) {
          displayPrivileges.push('Talent hunt & discovery');
        }
        if (this.privileges.candidatePools) {
          displayPrivileges.push('Candidate pools');
        }
      }

      if (
        this.privileges.processManagement &&
        this.privileges.teamManagement &&
        this.privileges.jobAdvertisement
      ) {
        displayPrivileges.push('Other (all)');
      } else {
        if (this.privileges.processManagement) {
          displayPrivileges.push('Process management');
        }
        if (this.privileges.teamManagement) {
          displayPrivileges.push('Team management');
        }
        if (this.privileges.jobAdvertisement) {
          displayPrivileges.push('Job advertisement management');
        }
      }

      const roles = this.getRolesBasedOnPrivileges();

      const requestBody: CreateInviteUserRequest = {
        email: email,
        roles: roles,
      };

      this.sendInvite(requestBody, displayPrivileges.join(', '));
    });

    this.emails = [];
    this.resetPrivileges();
  }

  private sendInvite(
    requestBody: CreateInviteUserRequest,
    displayPrivileges: string
  ) {
    this.registerService.inviteMember1(requestBody).subscribe({
      next: (response) => {
        if(response.body){
          this.invitedUsers.push({
            email: requestBody.email,
            displayPrivileges: displayPrivileges,
            userId: response.body?.id,
          });
        }
        else{
          this.errorMessage = response.errors?.[0]!;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public cancelInvitation(email: string) {
    const user = this.invitedUsers.find((u) => u.email === email);
    if (user && user.userId) {
      this.manageUserService.cancelInvitation(user.userId).subscribe({
        next: (response) => {
          this.invitedUsers = this.invitedUsers.filter(
            (u) => u.email !== email
          );
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Invitation cancelled successfully',
            life: 5000,
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Cancellation Failed!',
          });
        },
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Cancellation Failed!',
      });
      console.error(`User ID not found for email: ${email}`);
    }
  }

  private getRolesBasedOnPrivileges(): string[] {
    const roles: string[] = [];

    if (this.privileges.talentAcquisition) {
      roles.push('TALENT_HUNT_&_TALENT_DISCOVERY', 'CANDIDATE_POOLS');
    }
    if (
      this.privileges.talentHuntDiscovery &&
      !this.privileges.talentAcquisition
    ) {
      roles.push('TALENT_HUNT_&_TALENT_DISCOVERY');
    }
    if (this.privileges.candidatePools && !this.privileges.talentAcquisition) {
      roles.push('CANDIDATE_POOLS');
    }

    if (this.privileges.processManagement) {
      roles.push('PROCESS_MANAGEMENT');
    }
    if (this.privileges.teamManagement) {
      roles.push('TEAM_MANAGEMENT');
    }
    if (this.privileges.jobAdvertisement) {
      roles.push('JOB_ADVERTISEMENT_MANAGEMENT');
    }

    return roles;
  }

  resetPrivileges() {
    this.privileges = {
      talentAcquisition: false,
      talentHuntDiscovery: false,
      candidatePools: false,
      other: false,
      processManagement: false,
      teamManagement: false,
      jobAdvertisement: false,
    };
  }

  isInviteButtonDisabled(): boolean {
    return (
      this.emails.length === 0 ||
      (!this.privileges.talentAcquisition &&
        !this.privileges.talentHuntDiscovery &&
        !this.privileges.candidatePools &&
        !this.privileges.processManagement &&
        !this.privileges.teamManagement &&
        !this.privileges.jobAdvertisement)
    );
  }

  resetData() {
    this.emails = [];
    this.emailError = '';
    this.invitedUsers = [];
    this.resetPrivileges();
  }
}
