import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { forkJoin, map } from 'rxjs';
import { ProcessCandidateStatusControllerService, ManageUserControllerService, ProcessQuestionAnswerDto, CompanyControllerService, ProcessControllerService, CreateProcessQuestionAnswerRequest, UserControllerService, NegotiationControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-answers-questions-modal',
  templateUrl: './answers-questions-modal.component.html',
  styleUrls: ['./answers-questions-modal.component.scss']
})
export class AnswersQuestionsModalComponent {

  @Input() proposal: any;
  @Input() processId: number | undefined;
  @Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();
  public loggedUser: boolean = false;
  public employerName: string = '';
  public isOpen = false;
  public note: string = '';
  public errorMessage: string = '';
  public logo: string = '';
  public provider: boolean = false;
  constructor(private elementRef: ElementRef, private datePipe: DatePipe,
    private authService: UserControllerService,
    private processControllerService: ProcessControllerService,
    private comapnyControllerService: CompanyControllerService,
    private negotiationControllerService: NegotiationControllerService
  ) { }

  ngOnInit() {
    this.getRole();
  }

  getQuestions() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.proposal?.questionAnswers.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);
      this.proposal?.questionAnswers?.map((qa: any) => {
        qa.createdAt = String(this.datePipe.transform(qa.createdAt, 'dd.MM.yyyy HH:mm'));

        if (qa.authorCompanyType == 'EMPLOYER' && this.employerName.length == 0) {
          this.comapnyControllerService.getCompanyList('EMPLOYER').subscribe(res => {
            const employer = res.body!.find(employer => employer.id == qa.createdBy);
            this.employerName = employer?.name!;
            this.logo = employer?.logoUrl!;
          })
        }
      });
    }
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  getImageSource(item: any): string {
    if (item.authorCompanyType !== 'EMPLOYER') {
      return this.proposal.hrRProviderDto.logoUrl;
    }
    return this.logo;
  }

  addNote() {

    let question: CreateProcessQuestionAnswerRequest = {
      processId: this.processId!,
      questionAnswer: this.note
    };
    if (!this.provider) {
      question.hrProviderId = this.proposal.hrRProviderDto.id;
    }
    this.processControllerService.askAnswerQuestion(question).subscribe(() => {
      this.note = '';
      if(this.provider){
        this.negotiationControllerService.getAllByHrCompanyId(this.processId!).subscribe(res=>{
          this.proposal.questionAnswers = res.body!.questionAnswers!.sort((a,b) => a.id! - b.id!);;
        })
      }
      else{
        this.negotiationControllerService.getAllByProcessId(this.processId!).subscribe(res=>{
          this.proposal.questionAnswers = res.body?.find(qa=>qa.hrRProviderDto?.id == this.proposal.hrRProviderDto?.id)?.questionAnswers!.sort((a,b) => a.id! - b.id!);
          this.comapnyControllerService.getCompanyList('EMPLOYER').subscribe(res => {
            const employer = res.body!.find(employer => employer.id == res.body![1].id);
            this.employerName = employer?.name!;
            this.logo = employer?.logoUrl!;
          })
        })
      }
    })
  }

  public getRole() {
    this.authService.getUserRoles().subscribe((roles) => {
      if (
        roles.body!.includes('ROLE_EMPLOYER_ADMIN') ||
        roles.body!.includes('ROLE_EMPLOYER_MEMBER')
      ) {
      } else {
        this.provider = true;
      }
    });
  }
}

