/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { AmsResponseLong } from '../models/AmsResponseLong';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class DelegationControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param username
     * @returns AmsResponseLong OK
     * @throws ApiError
     */
    public delegate(
        username: string,
    ): Observable<AmsResponseLong> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/delegation/{username}',
            path: {
                'username': username,
            },
        });
    }
    /**
     * @param delegationId
     * @param delegationState
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public approve(
        delegationId: number,
        delegationState: 'NEW' | 'SEEN' | 'APPROVED' | 'REJECTED',
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/delegation/approval/{delegationId}/{delegationState}',
            path: {
                'delegationId': delegationId,
                'delegationState': delegationState,
            },
        });
    }
}
