import { Component } from '@angular/core';

@Component({
  selector: 'app-job-ads-preview-candidate-table',
  templateUrl: './job-ads-preview-candidate-table.component.html',
  styleUrls: ['./job-ads-preview-candidate-table.component.scss']
})
export class JobAdsPreviewCandidateTableComponent {
  applicants = [
    {
      name: 'Sanjin Omerovic',
      location: 'Türkiye',
      applicationDate: '15.10.2023',
      appliedVia: 'CV upload',
      status: 'Rejected',
    },
  ];
  
}
