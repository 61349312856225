<button class="note-button" (click)="isOpen = !isOpen">
    <img src="../../../assets/images/note-process.svg" alt="Note" *ngIf="notes!.length == 0">
    <img src="../assets/images/hr-provider/note-notification.svg" alt="Note" *ngIf="notes!.length > 0">
</button>
<ul class="list-none border-round shadow-2  absolute " *ngIf="isOpen">
    <div class="note-modal">
        <div *ngFor="let note of notes">
            <div class="note">
                <h5 class="mt-1">{{note?.companyName}}<span class="ml-1" *ngIf="note?.myNote">(you)</span>
                </h5>
                <span>{{note?.createdAt}}</span>
                <div class="text mt-2">
                    <p>{{note?.note}}</p>
                </div>
                <div class="translate-text">
                    <img src="../assets/images/translation.svg">
                    <div class="text">Translate to Bosnian Language</div>
                </div>
            </div>
        </div>
        <div *ngIf="notes?.length ==0" class="no-notes">
            There are currently no notes. Add some using the input below.
        </div>
    
        <div class="add-note">
            <input class="add" [(ngModel)]="note" placeholder="Add new note..." (keydown.enter)="addNote()">
        </div>
        <div *ngIf="errorMessage.length > 0" class="no-notes-error">
            {{errorMessage}}
        </div>
    </div>
</ul>