<div class="filter-dropdown ml-3" [ngClass]="{ isOpen: isOpen }">
    <button (click)="toggleDropdown()" class="dropdown-button">
      <img src="../../../assets/images/dropdownIcon.svg" class="dropdown-image" />
      Filter
    </button>
    <div *ngIf="isOpen" class="dropdown-container mt-3">
      <div class="dropdown-years">
        <div class="dropdown-content">
          <label for="minYears">Min. years of experience</label>
          <input
            [(ngModel)]="minYearsInput"
            placeholder="0"
            class="custom-input"
            id="minYears"
            type="number"
            min="0"
            max="50"
            (input)="onMinYearsInput($event)"
          />
        </div>
        <div class="dropdown-content">
          <label for="maxYears">Max. years of experience</label>
          <input
            [(ngModel)]="maxYearsInput"
            placeholder="0"
            class="custom-input"
            id="maxYears"
            type="number"
            min="0"
            max="50"
            (input)="onMaxYearsInput($event)"
          />
        </div>
      </div>
  
      <div class="dropdown-content">
        <label for="chipsInput">Skills</label>
        <p-chips
          class="skills"
          [(ngModel)]="values"
          placeholder="Type your skills and press enter"
          id="chipsInput"
          (onRemove)="onValueRemoved($event)"
          (onAdd)="onValueAdded($event)"
          [suggestions]="displayedSuggestions"
        >
        <ng-template let-item pTemplate="item">
          <span class="chip-item">{{ item }}</span>
          <img
            src="../../../assets/images/closeIconKeyword.svg"
            alt="remove"
            class="remove-icon"
            (click)="removeKeyword($event,item)"
          /> </ng-template
      >
      </p-chips>
      </div>
      <div class="dropdown-content">
        <label>Suggested</label>
        <div>
          <span *ngFor="let word of displayedSuggestions">
            <button (click)="addWordToSkills($event, word)" class="keyword">
              {{ word }}
            </button>
          </span>
        </div>
      </div>
      <div class="dropdown-content">
        <label for="multiSelectInput">Country</label>
        <p-multiSelect
          placeholder="Select country"
          id="multiSelectInput"
          [options]="locations"
          [(ngModel)]="selectedLocations"
          optionLabel="name"
          [virtualScroll]="false"
          [scrollable]="true"
          [scrollHeight]="'200px'"
          [lazy]="true"
          [loading]="loading"
          [virtualScrollItemSize]="42"
          class="location-dropdown"
          (onChange)="onSelect($event)"
        >
        </p-multiSelect>
      </div>
  
      <div class="buttons-wrapper">
        <button (click)="clearFilter()">Clear All</button>
        <button
          pButton
          label="Apply"
          tooltipPosition="top"
          class="custom-button p-mr-2"
          (click)="applyFilter()"
          (filterApplied)="filterApplied.emit($event)"
        ></button>
      </div>
    </div>
  </div>
  