import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig, DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { JobAdsScreenQuestionsComponent } from '../job-ads-screen-questions/job-ads-screen-questions.component';

@Component({
  selector: 'app-job-ads-preview-dialog',
  templateUrl: './job-ads-preview-dialog.component.html',
  styleUrls: ['./job-ads-preview-dialog.component.scss']
})
export class JobAdsPreviewDialogComponent {

  public process: any | undefined; 
  public editMode: boolean = false;
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig, 
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService) { 
      this.process = this.config.data.process
    }

  ngOnInit() {
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }

  closeDialog(){
    this.ref.close();
  }

  openDialog(){
    this.ref.close();
    const ref = this.dialogService.open(JobAdsScreenQuestionsComponent, {
      data: {
        process: this.process
      }
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(result=>{
    })
  }
}
