/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { ChangeProcessStatusRequest } from '../models/ChangeProcessStatusRequest';
import type { CreateProcessQuestionAnswerRequest } from '../models/CreateProcessQuestionAnswerRequest';
import type { CreateProcessRequest } from '../models/CreateProcessRequest';
import type { CreateProcessResponsibleRequest } from '../models/CreateProcessResponsibleRequest';
import type { CreateProcessStepNoteRequest } from '../models/CreateProcessStepNoteRequest';
import type { DeclineApproveProcessRequest } from '../models/DeclineApproveProcessRequest';
import type { DSResponseNegotiation } from '../models/DSResponseNegotiation';
import type { DSResponseProcessDto } from '../models/DSResponseProcessDto';
import type { DSResponseProcessStepNoteDto } from '../models/DSResponseProcessStepNoteDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { UpdateProcessRequest } from '../models/UpdateProcessRequest';
import type { UpdateProcessStepNoteRequest } from '../models/UpdateProcessStepNoteRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ProcessControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public getById1(
        id: number,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/process/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public updateProcess(
        id: number,
        requestBody: UpdateProcessRequest,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteProcess(
        id: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/process/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param processStepNoteId
     * @param requestBody
     * @returns DSResponseProcessStepNoteDto OK
     * @throws ApiError
     */
    public updateStepNote(
        processStepNoteId: number,
        requestBody: UpdateProcessStepNoteRequest,
    ): Observable<DSResponseProcessStepNoteDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/stepNote/{processStepNoteId}',
            path: {
                'processStepNoteId': processStepNoteId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processStepNoteId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public deleteStepNote(
        processStepNoteId: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/processManagement/process/stepNote/{processStepNoteId}',
            path: {
                'processStepNoteId': processStepNoteId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public sendProcessToBeApproved(
        processId: number,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/sendRequestToBeApproved/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public declineApprove(
        requestBody: DeclineApproveProcessRequest,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/declineApprove',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @param requestBody
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public changeStatus(
        id: number,
        requestBody: ChangeProcessStatusRequest,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/changeStatus/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processId
     * @param hrProviderId
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public cancelHrProvider(
        processId: number,
        hrProviderId: number,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/cancelHrProvider/{processId}/{hrProviderId}',
            path: {
                'processId': processId,
                'hrProviderId': hrProviderId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public approveProcess(
        processId: number,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/approveRequest/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param negotiationId
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public approveNegotiation(
        negotiationId: number,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/process/approveNegotiation/{negotiationId}',
            path: {
                'negotiationId': negotiationId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseProcessStepNoteDto OK
     * @throws ApiError
     */
    public createStepNote(
        requestBody: CreateProcessStepNoteRequest,
    ): Observable<DSResponseProcessStepNoteDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/process/stepNote',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public setResponsibleUser(
        requestBody: CreateProcessResponsibleRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/process/setResponsible',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public createInternal(
        requestBody: CreateProcessRequest,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/process/internal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public askAnswerQuestion(
        requestBody: CreateProcessQuestionAnswerRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/process/askAnswerQuestion',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseProcessDto OK
     * @throws ApiError
     */
    public create(
        requestBody: CreateProcessRequest,
    ): Observable<DSResponseProcessDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/process/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
