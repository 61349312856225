import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/auth.helper';
import { ManageUserControllerService, ProcessCandidateStatusControllerService, ProcessCandidateStatusNoteDto, UserControllerService } from 'src/app/oapi_client/data_symphony';

@Component({
  selector: 'app-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {

  @Input() notes: any[] = [];
  @Input() userId: number | undefined;
  @Input() processId: number = 0;
  
  public loggedUser: boolean = false;

  public isOpen = false;
  public note: string = '';
  public errorMessage: string = '';
  constructor(private elementRef: ElementRef, private datePipe: DatePipe, 
  private talentService: ProcessCandidateStatusControllerService,
  private authService: ManageUserControllerService,
  private route: ActivatedRoute,) { }

  ngOnInit(){
    this.mapDate();
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.processId = id;
    });
  }

  mapDate(){
    this.notes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    this.notes?.map(note=> note.createdAt = String(this.datePipe.transform(note.createdAt, 'dd.MM.yyyy HH:mm')));
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  addNote(){
    this.talentService.createNote({
      processCandidateStatusId: this.userId!,
      note: this.note
    }).subscribe(
      next => {
        this.note = ''; 
        this.updateNotes();
      },
      error => {
        this.errorMessage = 'This candidate is not editable.'; 
      }
    );
  }

  updateNotes(){
    this.talentService.getAllByProcessId1(this.processId).subscribe(res=>{
      this.notes = res.body?.find(u=>u.id == this.userId)?.notes!;
      this.mapDate();
    })
  }
}
