<div class="container">
    <div class="title">
        <h4>{{title}}</h4>
        <span class="description">{{paraph}}</span>
    </div>
    <form [formGroup]="form">
        <div class="content">
            <div class="form">
                <label>Ask a question</label>
                <div class="container-icons">
                    <div class="icons-p">
                        <span class="limit">{{characterCount}}/3000</span>
                    </div>
                </div>
                <textarea placeholder="Enter message here..." maxlength="3000" 
                    (ngModelChange)="incrementCount()" formControlName="note"></textarea>
            </div>
        </div>

        <div class="mt-4">
            <div class="button-class">
                <div class="position-right">
                    <button type="button" label="Send" class="p-button-save"
                        iconPos="left" (click)="save()" [disabled]="!form.valid">Send</button>
                </div>
                <div>
                    <button type="button" label="Cancel" class="p-button-cancel" iconPos="left" 
                        (click)="closeDialog()">Cancel</button>
                </div>
            </div>
        </div>
    </form>
</div>