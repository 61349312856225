/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { AmsResponseApiKeyDto } from '../models/AmsResponseApiKeyDto';
import type { AmsResponseBoolean } from '../models/AmsResponseBoolean';
import type { ApiKeyRequest } from '../models/ApiKeyRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ApiKeyControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns AmsResponseApiKeyDto OK
     * @throws ApiError
     */
    public createApiKey(
        requestBody: ApiKeyRequest,
    ): Observable<AmsResponseApiKeyDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/manage/apikey',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns AmsResponseBoolean OK
     * @throws ApiError
     */
    public deleteApiKey(
        requestBody: ApiKeyRequest,
    ): Observable<AmsResponseBoolean> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/api/v1/manage/apikey',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
