import { Component, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {
  ChangeProcessStatusRequest,
  CompanyDto,
  CreateLinkedinProfileFilterRequest,
  CreateProcessResponsibleRequest,
  LinkedinProfilesControllerService,
  ManageUserControllerService,
  MiniUserDto,
  ProcessCandidateStatusControllerService,
  ProcessControllerService,
  ProcessDto,
  StepControllerService,
  StepDto,
  UpdateProcessRequest,
  UserControllerService,
} from 'src/app/oapi_client/data_symphony';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { DialogService, DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ConfirmDialogOutgoingRequestComponent } from '../../hr-providers/outgoing-requests-list/upsert-outgoing-request/confirm-dialog-outgoing-request/confirm-dialog-outgoing-request.component';
import { ConfirmationDialogComponent } from '../../negotiation-dialogs/confirmation-dialog/confirmation-dialog.component';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Subscription } from 'rxjs';
import { SuccessDialogComponent } from '../../negotiation-dialogs/success-dialog/success-dialog.component';
import { take } from 'rxjs/operators';
import { OutgoingRequestReviewProviderComponent } from './outgoing-request-review-provider/outgoing-request-review-provider.component';
import { MessageService } from 'primeng/api';
import { JobAdsPreviewDialogComponent } from '../../job-ads/job-ads-preview-dialog/job-ads-preview-dialog.component';
import { ErrorDialogComponent } from '../../negotiation-dialogs/error-dialog/error-dialog.component';

interface ProcessStep {
  name: string;
  details?: string;
  isOpen?: boolean;
  id?: number;
  isCompleted?: boolean;
  showUndoMessage?: boolean;
  timeoutId?: any;
  displayedStatusCounts: { label: string; count: number }[];
  count: number | undefined;
  statusCounts?: { [status: string]: number };
}

@Component({
  selector: 'app-outgoing-request-details',
  templateUrl: './outgoing-request-details.component.html',
  styleUrls: ['./outgoing-request-details.component.scss'],
})
export class OutgoingRequestDetailsComponent {
  constructor(
    private location: Location,
    private processManagementService: ProcessControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService,
    private router: Router,
    private stepControllerService: StepControllerService,
    private talentService: ProcessCandidateStatusControllerService,
    private readonly linkedinProfileService: LinkedinProfilesControllerService,
    public manageUserControllerService: ManageUserControllerService,
    public messagingService: MessageService,
    public userControllerService: UserControllerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.providers = navigation.extras.state['providers'];
    }
  }

  public providers: CompanyDto[] = [];
  public process: ProcessDto | undefined;
  public keyword: string | null = null;
  public processSteps: ProcessStep[] = [];
  public id: number = 0;
  public allCandidatesByProcess: any[] = [];
  public videoUrl: any;
  public steps: any[] = [];
  public orgCandidatesByProces: any[] = [];
  confirmationModalVisible: boolean = false;
  @ViewChild('confirmationModal') confirmationModal: ModalComponent | undefined;
  private acceptSubscription: Subscription | undefined;
  public isInfoVisible = false;
  public isLoading: boolean = true;
  public users: MiniUserDto[] = [];
  public selectedUser: any;
  public adminEmployer: boolean = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe((params) => {
      const id = +params['id'];
      this.id = id;
      this.getProcessById(id);
      this.getCandidates();
      this.checkPeopleInProcess(this.id);
    });
  }

  public setAssigne(){
    if(this.selectedUser?.id != 0){
      const request: CreateProcessResponsibleRequest = {userId: this.selectedUser!.id, processId: this.id }
      this.processManagementService.setResponsibleUser(request).subscribe({
        next: ()=>{
        const userEmail = this.users.find(user=>user.id == this.selectedUser!.id)?.email;
        this.messagingService.add({
          severity: 'success',
          summary: 'Success',
          detail: `You have successfully assigned ${userEmail} to the process.`,
          life: 3000,
        });
      }, 
      error: ()=>{
        this.messagingService.add({
          severity: 'error',
          summary: 'Error',
          detail: `The selected user couldn't be assigned.`,
          life: 3000,
        });
      }
      })
    }
  }

  getProcessById(id: number): void {
    this.processManagementService.getById1(id).subscribe({
      next: (response) => {
        this.process = response?.body;
        this.setProcessSteps(response.body?.steps, response.body?.stepOrder);
        this.breadcrumbService.setGroupName(
          response?.body?.requirement?.name ?? ''
        );
        this.userControllerService.getUserRoles().subscribe((res)=>{
          if(res.body?.includes('ROLE_EMPLOYER_ADMIN')){
            this.adminEmployer = true;
            this.manageUserControllerService.userTeammates().subscribe((users)=>{
              this.users = users.body!.filter(f=>f.status?.toLocaleLowerCase() == 'active');
              if(this.process?.responsibleId && this.users!.length != 0){
                this.selectedUser = this.users.find(user=>this.process?.responsibleId == user.id)
              }
            })
          }
        })
        
        this.isLoading = false;
      },
      error: (error) => {
        this.manageUserControllerService.userTeammates().subscribe((users)=>{
          this.users = users.body!.filter(f=>f.status?.toLocaleLowerCase() == 'active');
        })
        console.error('Error fetching process:', error);
        this.isLoading = false;
      },
    });
  }


  openJobAdDialog(){
    const ref = this.dialogService.open(JobAdsPreviewDialogComponent, {
      data: {
        process: this.process
      }
    });

    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(result=>{
    })
  }

  navigateToPreviousPage(): void {
    this.location.back();
  }

  setProcessSteps(
    steps: StepDto[] | undefined,
    stepOrder: number[] | undefined
  ): void {
    const orderedSteps = stepOrder?.map((orderId) =>
      steps?.find((step) => step.id === orderId)
    );
    this.processSteps = orderedSteps?.filter(
      (step) => step !== undefined
    ) as ProcessStep[];
  }

  assignToHrProvider() {
    if(this.providers.length > 0){
      const request = {
        hrProviderIds: this.providers
          .map((provider) => provider.id)
          .filter((id): id is number => id !== undefined),
      } as UpdateProcessRequest;
      const ref = this.dialogService.open(ConfirmDialogOutgoingRequestComponent, {
        data: {
          request: request,
          providers: this.providers,
          requestId: this.id,
          assignToHrProvider: true,
        },
      });
  
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
  
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  
      ref.onClose.subscribe(() => {
        this.getProcessById(this.id);
      });
      return;
    }

    this.router.navigate([`/hr-providers/${this.id}`]);
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  formatJobType(jobType: string): string {
    return this.processUtils.formatJobType(jobType);
  }

  formatExperience(min: number | undefined, max: number | undefined): string {
    return this.processUtils.formatExperience(min, max);
  }

  formatLanguages(languages: Array<string> | undefined): string {
    return this.processUtils.formatLanguages(languages);
  }

  formatJobTypes(jobTypes: Array<string> | undefined): string {
    return this.processUtils.formatJobTypes(jobTypes);
  }

  formatWorkingTypes(workingTypes: Array<string> | undefined): string {
    return this.processUtils.formatWorkingTypes(workingTypes);
  }

  getStepList() {
    this.stepControllerService.getStepList().subscribe((steps) => {
      if (steps.body)
        this.steps = steps.body?.map((item) => ({
          label: item['name'],
          value: item['id'],
        }));
    });
  }

  getVideo() {
    this.talentService.getCandidateVideo(36).subscribe({
      next: (res: Blob) => {
        this.videoUrl = res;
      },
      error: (err) => {
        console.error('Error fetching video:', err);
      },
    });
  }

  getCandidates() {
    this.talentService.getAllByProcessId1(this.id).subscribe((res) => {
      if (res.body) {
        let candidatesByProcess = res.body.sort((a, b) => a.id! - b.id!);

        const stepCandidatesMap = new Map<number, Map<string, any>>();
        const memberIds: string[] = [];

        candidatesByProcess.forEach((candidate) => {
          const stepId = candidate.stepId!;
          const memberId = candidate.memberId!;
          memberIds.push(memberId);

          if (!stepCandidatesMap.has(stepId)) {
            stepCandidatesMap.set(stepId, new Map<string, any>());
          }

          const candidatesInStep = stepCandidatesMap.get(stepId)!;

          if (!candidatesInStep.has(memberId)) {
            candidatesInStep.set(memberId, candidate);
          } else {
            const existingCandidate = candidatesInStep.get(memberId)!;
            if (candidate.id! < existingCandidate.id!) {
              candidatesInStep.set(memberId, candidate);
            }
          }
        });

        const requestBody: CreateLinkedinProfileFilterRequest = {
          memberId: memberIds,
          pageSize: memberIds.length,
        };

        this.linkedinProfileService
          .filter1(requestBody)
          .subscribe((profileResponse) => {
            const profiles = profileResponse.body?.content || [];

            candidatesByProcess = Array.from(
              stepCandidatesMap.values()
            ).flatMap((stepMap) => Array.from(stepMap.values()));

            this.allCandidatesByProcess = candidatesByProcess.map((talent) => {
              const profile = profiles.find(
                (p) =>
                  p.publicMemberId === talent.memberId ||
                  p.publicLinkedinMemberId === talent.memberId
              );
              return {
                ...talent,
                profile: profile ? profile : null,
              };
            });

            this.orgCandidatesByProces = this.allCandidatesByProcess;
          });
      }
    });
  }

  filterCandidates(step: any) {
    step.isOpen = !step.isOpen;
    this.processSteps.map((process) =>
      process !== step ? (process.isOpen = false) : ''
    );
    this.allCandidatesByProcess = this.orgCandidatesByProces.filter(
      (candidate) => candidate.stepId === step.id
    );
  }

  removeStepProcess(step: any) {
    this.dialogService
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Are you sure you want to remove ${step.name} step from the request?`,
          paraph: `It will remove all the candidates if they are added.`,
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          const steps = this.processSteps.filter(
            (processStep) => step.id !== processStep.id
          );
          this.processManagementService
            .updateProcess(this.id, { stepIds: steps.map((step) => step.id!) })
            .subscribe(() => this.getProcessById(this.id));
        }
      });
  }

  acceptAsCompleted() {
    if (this.confirmationModal) {
      this.confirmationModal.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to accept this process as completed?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      if (this.acceptSubscription) {
        this.acceptSubscription.unsubscribe();
      }

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });

      this.acceptSubscription = this.confirmationModal.accept
        .pipe(take(1))
        .subscribe(() => {
          this.confirmationModal!.isVisible = false;
          this.acceptProcessAsCompleted();
          const ref = this.dialogService.open(SuccessDialogComponent, {
            data: {
              title: 'Successfully completed!',
              paraph:
                'You have successfully accepted the process as completed.',
            },
          }).onClose.subscribe(()=>{
            const ref = this.dialogService.open(OutgoingRequestReviewProviderComponent, {data:{processId: this.id}})
            const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
            const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
            const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
            dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
            this.ngOnInit();
          });
        }, error=>{
          const ref = this.dialogService.open(ErrorDialogComponent, {
            data: {
              title: "Completion Failed!",
              paraph: "You failed to accept the process as completed."
            },
          })
          const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
          const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
      
          const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
          dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
        });
    }
  }

  showInfo() {
    this.isInfoVisible = true;
  }

  hideInfo() {
    this.isInfoVisible = false;
  }

  acceptProcessAsCompleted() {
    const requestBody: ChangeProcessStatusRequest = {
      processStatus: ChangeProcessStatusRequest.processStatus.SUCCEED,
    };

    this.processManagementService.changeStatus(this.id, requestBody).subscribe({
      next: (response) => {
        this.getProcessById(this.process?.id!);
      },
      error: (error) => {
        console.error('Error changing process status', error);

        alert('Failed to mark process as completed. Please try again.');
      },
    });

    
  }

  checkPeopleInProcess(id: number): void {
    this.talentService.countByProcessId(id).subscribe((res) => {
      const statusLabels = {
        IN_PROGRESS: 'In Progress',
        SUCCEED: 'Succeeded',
        FAILED: 'Failed',
      };

      this.processSteps.forEach((step) => {
        step.statusCounts = step.statusCounts || {};
        Object.keys(step.statusCounts).forEach((status) => {
          step.statusCounts![status] = 0;
        });
        const stepCounts = res.body!.filter(
          (count) => count.stepId === step.id
        );

        stepCounts.forEach((stepCount) => {
          if (stepCount.status) {
            step.statusCounts![stepCount.status] = stepCount.count || 0;
          }
        });
      });
      this.processSteps.forEach((step) => {
        if (step.statusCounts) {
          const orderedCounts = [
            {
              label: statusLabels.SUCCEED,
              count: step.statusCounts['SUCCEED'] || 0,
            },
            {
              label: statusLabels.FAILED,
              count: step.statusCounts['FAILED'] || 0,
            },
            {
              label: statusLabels.IN_PROGRESS,
              count: step.statusCounts['IN_PROGRESS'] || 0,
            },
          ];

          step.displayedStatusCounts = orderedCounts.map((statusCount) => ({
            label: statusCount.label,
            count: statusCount.count,
          }));
        }
      });
    });
  }
  getStatusDotClass(label: string): string {
    switch (label) {
      case 'SUCCEED':
        return 'status-dot succeeded';
      case 'FAILED':
        return 'status-dot failed';
      case 'IN_PROGRESS':
        return 'status-dot in-progress';
      default:
        return 'status-dot';
    }
  }
  
}
