<div class="mt-5">
    <div style="display: inline-flex;">
        <h4 class="mt-2">Applicants</h4>
        <app-job-ads-filter></app-job-ads-filter>
    </div>
    <div class="mt-3">
        <p-table [value]="applicants" [responsiveLayout]="'scroll'" class="custom-table">
            <ng-template pTemplate="header">
              <tr>
                <th>Applicant</th>
                <th>Location</th>
                <th>Application Date</th>
                <th>Applied Via</th>
                <th>Status</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-applicant>
              <tr class="row-accessories" routerLink="/job-ads/preview-candidate/1">
                <td>{{ applicant.name }}</td>
                <td>{{ applicant.location }}</td>
                <td>{{ applicant.applicationDate }}</td>
                <td>{{ applicant.appliedVia }}</td>
                <td>
                  <span
                    [ngClass]="{
                      'status-rejected': applicant.status === 'Rejected',
                      'status-accepted': applicant.status === 'Accepted'
                    }"
                  >
                    {{ applicant.status }}
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
    </div>

</div>
