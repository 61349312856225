import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from 'src/app/helpers/breadcrumb.service';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { HRProviderOverviewDto, CompanyControllerService, DSResponseHRProviderOverviewDto } from 'src/app/oapi_client/data_symphony';

interface WorkHistoryItem {
  title: string;
  company: string;
  date: string;
  totalCost: string;
  reviews: {
    title: string;
    comment: string;
    starCount: number;
    createdBy: string;
    createdAt: string;
  }[];
}
@Component({
  selector: 'app-job-ads-preview-candidate',
  templateUrl: './job-ads-preview-candidate.component.html',
  styleUrls: ['./job-ads-preview-candidate.component.scss']
})


export class JobAdsPreviewCandidateComponent {
  company: HRProviderOverviewDto | undefined;
  workHistory: WorkHistoryItem[] = [];
  public averageRating: number = 0;
  totalBudget: string = '';
  public experiences = [{
    institutionName: 'Nexera Solutions',
    titles: [{
      positionTitle: 'Full stack developer',
      fromDate: new Date(),
      toDate: new Date(),
      duration: '12y',
      about: 'Dui faucibus accumsan tellus cursus. Nulla ultricies risus orci lacinia ut donec aliquet. Viverra viverra amet adipiscing accumsan tristique quis eu.Amet nunc nunc ipsum eget dolor ipsum.'
    }]
  }, {
    institutionName: 'Nexera Solutions',
    titles: [{
      positionTitle: 'Full stack developer',
      fromDate: new Date(),
      toDate: new Date(),
      duration: '12y',
      about: 'Dui faucibus accumsan tellus cursus. Nulla ultricies risus orci lacinia ut donec aliquet. Viverra viverra amet adipiscing accumsan tristique quis eu.Amet nunc nunc ipsum eget dolor ipsum.'
    }]
  }]

  questions = [
    {
      text: 'Question lorem ipsum dolor sit amet consectetur. Sed mollis ut hendrerit aliquam sed a. Turpis?',
      qualification: 'Must-have qualification',
      idealAnswer: 'Yes',
      userAnswer: 'Yes',
    },
    {
      text: 'Question lorem ipsum dolor sit amet consectetur. Sed mollis ut hendrerit aliquam sed a. Turpis?',
      qualification: '',
      idealAnswer: 'No',
      userAnswer: 'No',
    },
    {
      text: 'Lorem ipsum dolor sit amet consectetur. Ultrices ultrices luctus hendrerit cursus lobortis. Turpis placerat tempor lectus blandit?',
      qualification: '',
      idealAnswer: '3',
      userAnswer: '4',
    },
    {
      text: 'Question lorem ipsum dolor sit amet consectetur. Sed mollis ut hendrerit aliquam sed a. Turpis?',
      qualification: 'Must-have qualification',
      idealAnswer: '1',
      userAnswer: '1',
    },
  ];
  
  constructor(
    private companyService: CompanyControllerService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private processUtils: ProcessUtilsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params['id'];
    });
  }

  formatDate(dateString: string | undefined): string {
    return this.processUtils.formatDate(dateString);
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }
}
