/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateLinkedinSearchLinkJobRequest } from '../models/CreateLinkedinSearchLinkJobRequest';
import type { CreateUniversalRequest } from '../models/CreateUniversalRequest';
import type { DSResponsePageLinkedinCompanyDto } from '../models/DSResponsePageLinkedinCompanyDto';
import type { DSResponseString } from '../models/DSResponseString';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class LinkedinCompanyControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public createLinkedinSpecificCompanyJob(
        requestBody: CreateUniversalRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/company/findSpecific',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public createLinkedinCompanyJob(
        requestBody: CreateLinkedinSearchLinkJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/company/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param jobId
     * @param pageIndex
     * @returns DSResponsePageLinkedinCompanyDto OK
     * @throws ApiError
     */
    public getResults2(
        jobId: string,
        pageIndex?: number,
    ): Observable<DSResponsePageLinkedinCompanyDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/company/results/{jobId}',
            path: {
                'jobId': jobId,
            },
            query: {
                'pageIndex': pageIndex,
            },
        });
    }
}
