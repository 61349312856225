<div class="job-dialog" style="padding-left: 20px;
padding-right: 20px;" [ngClass]="routeMode ? 'card': ''">
    <div class="job-dialog-header" *ngIf="!routeMode">
        <div class="company-info">
            <div>
                <img [src]="process.acceptedHRProvider?.logoUrl" (error)="onImageError($event)" alt="Logo" />
            </div>
            <div class="company-details ml-2">
                <h2>{{process.acceptedHRProvider?.name}}</h2>
                <p>{{process.acceptedHRProvider?.webSiteUrl}}</p>
            </div>
        </div>
        <div class="edit-button" style="align-items: center; margin-top: 40px;">
            <button class="image-button mr-2" pTooltip="Edit" tooltipPosition="bottom">
                <img src="../../../assets/images/edit.svg" alt="Edit Image" />
            </button>
        </div>
    </div>
    <hr />
    <div class="content" style="padding-left: 50px;">
        <div class="content-header">
            <h3>Screening questions <span class="optional">(Optional)</span></h3>
            <p>The applicant will be able to answer with YES and NO options and numerically. Please write questions that
                fit that format.</p>
        </div>
        <div class="header-questions-form">
            <div class="questions-form" style="margin-top: 20px;" *ngFor="let question of questionsLabel">
                <div class="question">
                    <div>
                        <label style="width: 100%;">{{question.label}}</label>
                    </div>
                    <div style="display: inline-flex;">
                        <input type="text" class="question-input">
                        <button class="pi pi-trash" (click)="remove()"></button>
                    </div>
                </div>
                <div class="qualification-container">
                    <div class="checkbox-section">
                        <input type="checkbox" id="mustHave" />
                        <label for="mustHave" class="checkbox-label">
                            Must-have qualification
                        </label>
                    </div>
                    <div class="response-section">
                        <div class="response-type">
                            <label for="responseType">Response type</label>
                            <p-dropdown [options]="responseOptions" [(ngModel)]="selectedResponseType"
                                placeholder="Select"></p-dropdown>
                        </div>
                        <div class="ideal-answer">
                            <label for="idealAnswer">Ideal answer</label>
                            <p-dropdown [options]="idealAnswerOptions" [(ngModel)]="selectedIdealAnswer"
                                placeholder="Select"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;">
            <i class="pi pi-plus" (click)="addMoreQuestions()"><span class="add-more-questions">Add more
                    questions</span></i>
        </div>
        <hr />
        <div class="content-header">
            <h3>Qualification setting</h3>
            <div class="checkbox-section" style="display: inline-flex;">
                <input type="checkbox" id="mustHave" />
                <label for="mustHave" class="checkbox-label">
                    Filter out and send rejections to applicants who don‘t meet any must-have qualifications.
                </label>
            </div>
        </div>
    </div>
    <div [ngClass]="routeMode ? 'mt-7':'mt-4'" style="display: flex; justify-content: space-between;">
        <div>
            <button type="button" label="Cancel" class="p-button-cancel" iconPos="left" style="margin-left: 30px;"
                (click)="cancelEdit()">
                Cancel
            </button>
        </div>
        <div class="down-buttons">
            <button type="button" label="Cancel" class="p-button-cancel back  mr-5" iconPos="left"
                (click)="cancelEdit()" *ngIf="!routeMode">
                Go Back
            </button>
            <button type="submit" class="p-button-save internal" iconPos="left" (click)="save()">
                {{routeMode ? 'Create Job Ad' : 'Save Changes'}}
            </button>
        </div>
    </div>
</div>