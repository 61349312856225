<div class="table-wrapper">
  <div>
    <h1>Proposals</h1>
    <p>
      If you accept a proposal, the rest of them are automatically declined.
    </p>
    <p class="sort">View: <span>All</span></p>
  </div>
  <div class="proposals-table">
    <p-table [value]="proposals" [paginator]="true" [rows]="10">
      <ng-template pTemplate="header">
        <tr>
          <th class="rating-th">Sent to</th>
          <th class="rating-th">Rating</th>
          <th class="text-center">Number of jobs</th>
          <th class="date-of-sending-th">Date of sending</th>
          <th style="padding-left: 28px;">Proposal</th>
          <th class="text-right pr-5" style="width: 30%;">Actions</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-proposal>
        <tr>
          <td>{{proposal.hrRProviderDto.name}}</td>
          <td class="rating-td">
            <span>{{ proposal.meanStarCount | number: '1.1-1' }}</span>
            <p-rating 
              [(ngModel)]="proposal.hrRProviderDto.meanStarCount" 
              [readonly]="true" 
              [cancel]="false" 
              [min]="0" 
              [max]="1" 
              [stars]="1" 
              [ngModelOptions]="{ standalone: true }" 
              [value]="proposal.hrRProviderDto.meanStarCount > 1 ? 1 : proposal.hrRProviderDto.meanStarCount">
            </p-rating>
          </td>
          <td class="text-center">{{proposal.hrRProviderDto.completedProcessCount}} 
          </td>
          <td>{{ proposal.dateOfSending | date:'short' }}</td>
          <td class="text-center status-proposal">
            <ng-container 
              *ngIf="proposal?.negotiationList?.at(-1)?.isHrAgreed == true && proposal?.negotiationList?.at(-1)?.isEmployerAgreed == false">
              <div class="proposal-pending-status declined text-center">
              Declined
              </div>
            </ng-container>
            <ng-container
              *ngIf="proposal?.negotiationList?.at(-1)?.offer == null && proposal?.negotiationList?.at(-1)?.isHrAgreed == null && proposal?.negotiationList?.at(-1)?.isEmployerAgreed == null">
              <div class="proposal-pending-status"><span class="pl-2 pr-2">Pending</span></div>
            </ng-container>
            <ng-container *ngIf="proposal?.negotiationList?.at(-1)?.offer !== null && proposal?.negotiationList?.at(-1)?.isEmployerAgreed == null">
              <div class="proposal-offer-status text-center">
                <span class="pl-2 pr-2"> {{proposal?.negotiationList?.at(-1)?.offer?.amount + ' ' + proposal?.negotiationList?.at(-1)?.offer?.type}}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="proposal?.negotiationList?.at(-1)?.isEmployerAgreed == true">
              <div class="proposal-offer-status  accepted text-center" >
                Accepted -
                {{proposal?.negotiationList?.at(-1)?.offer?.amount + ' ' + proposal?.negotiationList?.at(-1)?.offer.type}}
              </div>
            </ng-container>
           
          </td>
          <td class="text-right">
            <div *ngIf="proposal?.negotiationList?.length == 0" style="display: inline-flex; justify-content: end;">
              <app-answers-questions-modal [proposal]="proposal" [processId]="processId"></app-answers-questions-modal>
              <button pButton type="button" label="Cancel" class="proposal-pending-action" (click)="cancelProvider(proposal?.negotiationList?.at(-1)?.hrProviderId)"></button>
            </div>
            <div *ngIf="proposal?.negotiationList?.at(-1)?.offer != null && proposal?.negotiationList?.at(-1)?.isEmployerAgreed == null && proposal?.negotiationList?.at(-1)?.isHrAgreed == true">
              <button pButton type="button" label="Accept" class="proposal-accept-action mr-2"
                (click)="openDialog('Accept', proposal?.negotiationList?.at(-1)?.id, proposal?.negotiationList?.at(-1)?.offer.amount, proposal?.negotiationList?.at(-1)?.offer.type)"></button>
              <button pButton type="button" label="Decline" class="proposal-decline-action mr-2"
                (click)="openDialog('Decline',proposal?.negotiationList?.at(-1)?.id, undefined, undefined, proposal?.negotiationList?.at(-1)?.employerId)"></button>
              <button pButton type="button" label="Counter Offer" class="proposal-counter-offer"
                *ngIf="proposal.negotiationList.length == 1"
                (click)="openDialog('Counter', proposal?.hrRProviderDto.id)"></button>
            </div>
            <div
              *ngIf="proposal?.negotiationList?.at(-1)?.offer != null && proposal?.negotiationList?.at(-1)?.employerId == proposal?.negotiationList?.at(-1)?.offeredById && proposal?.negotiationList?.at(-1)?.isEmployerAgreed != true">
              <div *ngIf="proposal?.negotiationList?.at(-1)?.isHrAgreed == null">
                Counter offer sent
              </div>
              <div *ngIf="proposal?.negotiationList?.at(-1)?.isHrAgreed == false">
                Counter offer declined
              </div>
            </div>
            <div *ngIf="proposal?.negotiationList?.at(-1)?.isHrAgreed == true && proposal?.negotiationList?.at(-1)?.isEmployerAgreed == false" class="pr-2">
              <img src="../../../assets/images/deleteIcon.svg" (click)="deleteProposal(proposal?.negotiationList?.at(-1)?.hrProviderId)" class="image-delete" />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>