import { Component, Input, OnInit, Output } from '@angular/core';
import {
  CompanyDto,
  MiniHRProviderOverviewDto,
} from 'src/app/oapi_client/data_symphony';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hr-provider',
  templateUrl: './hr-provider.component.html',
  styleUrls: ['./hr-provider.component.scss'],
})
export class HrProviderComponent implements OnInit {
  @Input() public provider!: any;
  @Input() isRequestSent: boolean = false;
  @Output() public addProvider = new EventEmitter<CompanyDto>();
  public select: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
  }

  selectProvider() {
    this.select = !this.select;
    this.provider.checked = !this.provider.checked ;
    this.addProvider.emit(this.provider!);
  }

  navigateToProviderDetails(id: number | undefined) {
    this.router.navigate(['/provider-details', id]);
  }

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }
}
