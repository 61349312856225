import {
  Component,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  CreateLinkedinProfileFilterRequest,
  GroupControllerService,
  DSResponseListGroupModelDto,
  GroupModelDto,
} from '../../oapi_client/data_symphony';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { ParameterService } from 'src/app/helpers/parameter.service';
import { PatternService } from 'src/app/helpers/pattern.service';
import {
  DialogService,
  DynamicDialogComponent,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { UpsertCandidateModalComponent } from './upsert-candidate-modal/upsert-candidate-modal.component';
import { MessageService } from 'primeng/api';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { DataService } from 'src/app/helpers/data.service';
import { AuthHelper } from 'src/app/helpers/auth.helper';

@Component({
  selector: 'app-candidate-pool',
  templateUrl: './candidate-pool.component.html',
  styleUrls: ['./candidate-pool.component.scss'],
  providers: [DialogService],
})
export class CandidatePoolComponent implements OnInit, AfterViewInit {
  filter: CreateLinkedinProfileFilterRequest = {};
  
  @ViewChild('confirmationModal') confirmationModal: ModalComponent | undefined;
  @ViewChild('dt') dataTable: Table | undefined;

  isLoading: boolean = true;
  groups$: GroupModelDto[] = [];
  dialogRef: DynamicDialogRef | undefined;
  showModal: boolean = true;
  isEditing: boolean = false;
  modalHeaderText: string = '';
  modalButtonText: string = '';
  selectedGroup: GroupModelDto | undefined;
  confirmationModalVisible: boolean = false;
  currentUserID: number = 0;
  searchText: string = '';
  orgGroups$: GroupModelDto[] = [];
  numberOfPools: number = 0;
  totalMemberCount: number = 0;
  isExpanded: boolean = false;
  hasEllipsis: boolean = false;

  constructor(
    private readonly groupService: GroupControllerService,
    private router: Router,
    protected paginationService: PaginationService,
    protected parameterService: ParameterService,
    private readonly dialogService: DialogService,
    public patternService: PatternService,
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
    private messageService: MessageService,
    private dataService: DataService,
    private authHelper: AuthHelper
  ) {}

  ngOnInit() {
    this.isLoading = true;
    if (this.dataTable) {
      this.dataTable.first =
        this.paginationService.lazyProfileListPaginationState.first;
      this.dataTable.rows =
        this.paginationService.lazyProfileListPaginationState.rows;
    }
    this.getGroups();

    this.authHelper.currentUser.subscribe(
      (user) => (this.currentUserID = user.id)
    );
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getGroups() {
    this.groupService.getAll1().subscribe(
      (response: DSResponseListGroupModelDto) => {
        this.zone.run(() => {
          this.groups$ = response.body || [];
          this.orgGroups$ = response.body || [];
          this.paginationService.lazyProfileListPaginationState.totalRecords = 0;
          if (this.dataTable) {
            this.dataTable.totalRecords =
              this.paginationService.lazyProfileListPaginationState.totalRecords;
          }

          if (response.body) {
            this.calculatePoolsAndMembers(response.body);
          }

          this.isLoading = false;
          this.cdr.detectChanges();
        });
      },
      (error: any) => {
        alert(error);
        this.isLoading = false;
      }
    );
  }

  calculatePoolsAndMembers(groups: GroupModelDto[]) {
    this.numberOfPools = groups.length;
    this.totalMemberCount = groups.reduce((accumulator, current) => {
      return accumulator + current.memberCount!;
    }, 0);
  }

  createCandidatePool() {
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {});
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
    this.cdr.detectChanges();

    ref.onClose.subscribe(() => {
      this.ngOnInit();
    });

    return ref;
  }

  searchPools() {
    this.searchText = this.searchText.toLocaleLowerCase();
    this.groups$ = this.orgGroups$.filter((group) => {
      return (
        group.description?.toLocaleLowerCase().includes(this.searchText) ||
        group.name?.toLocaleLowerCase().includes(this.searchText) ||
        group.keywordList?.toLocaleLowerCase().includes(this.searchText)
      );
    });
  }

  viewProfileDetails(groupId: number): void {
    this.router.navigate(['/candidate-member', groupId]);
  }

  editCandidatePool(groupId: number) {
    const group = this.groups$.find((group) => group.id === groupId);
    const ref = this.dialogService.open(UpsertCandidateModalComponent, {
      data: group,
    });
    const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
    const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;

    const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;

    ref.onClose.subscribe(() => {
      this.ngOnInit();
    });

    return ref;
  }

  deleteGroup(groupId: number) {
    if (this.confirmationModal) {
      this.confirmationModal!.isVisible = true;
      this.confirmationModal.header =
        'Are you sure you want to delete this group?';
      this.confirmationModal.message = "Once you confirm you can't go back.";
      this.confirmationModal.acceptText = 'Yes';
      this.confirmationModal.cancelText = 'No';
      this.confirmationModal.isVisible = true;

      this.confirmationModal.accept.subscribe(() => {
        this.groupService.deleteGroup(groupId).subscribe((result) => {
          this.dataService.updateSearches();
          this.getGroups();
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Deleted Successfully',
            life: 3000,
          });
        });
        this.confirmationModal!.isVisible = false;
      });

      this.confirmationModal.cancel.subscribe(() => {
        this.confirmationModal!.isVisible = false;
      });
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  splitKeywords(keywordList: string): string[] {
    return keywordList
      .split(',')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword !== '');
  }

  getTruncatedKeywords(keywordList: string): string[] {
    if (!keywordList) return [];

    const keywords = keywordList.split(',');
    const containerWidth = 310;
    let displayedKeywords: string[] = [];
    let accumulatedWidth = 0;
    this.hasEllipsis = false;

    for (const keyword of keywords) {
      const tempSpan = document.createElement('span');
      tempSpan.style.position = 'absolute';
      tempSpan.style.visibility = 'hidden';
      tempSpan.style.whiteSpace = 'nowrap';
      tempSpan.innerText = keyword.trim();

      document.body.appendChild(tempSpan);
      const keywordWidth = tempSpan.offsetWidth;
      document.body.removeChild(tempSpan);

      if (accumulatedWidth + keywordWidth <= containerWidth) {
        displayedKeywords.push(keyword.trim());
        accumulatedWidth += keywordWidth + 40;
      } else {
        this.hasEllipsis = true;
        break;
      }
    }

    return displayedKeywords;
  }
}
