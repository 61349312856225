/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { DSResponseContactInfoDto } from '../models/DSResponseContactInfoDto';
import type { DSResponsePageContactInfoDto } from '../models/DSResponsePageContactInfoDto';
import type { FilterContactInfoRequest } from '../models/FilterContactInfoRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class ContactInfoControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponsePageContactInfoDto OK
     * @throws ApiError
     */
    public filterContactInfo(
        requestBody: FilterContactInfoRequest,
    ): Observable<DSResponsePageContactInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/contactInfo/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns DSResponseContactInfoDto OK
     * @throws ApiError
     */
    public getById5(
        id: string,
    ): Observable<DSResponseContactInfoDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/contactInfo/{id}',
            path: {
                'id': id,
            },
        });
    }
}
