/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateLinkedinSearchLinkJobRequest } from '../models/CreateLinkedinSearchLinkJobRequest';
import type { DSResponseLinkedinPostDto } from '../models/DSResponseLinkedinPostDto';
import type { DSResponsePageLinkedinPostDto } from '../models/DSResponsePageLinkedinPostDto';
import type { DSResponseString } from '../models/DSResponseString';
import type { FilterLinkedinPostRequest } from '../models/FilterLinkedinPostRequest';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class LinkedinPostControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param requestBody
     * @returns DSResponsePageLinkedinPostDto OK
     * @throws ApiError
     */
    public filter2(
        requestBody: FilterLinkedinPostRequest,
    ): Observable<DSResponsePageLinkedinPostDto> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/post/filter',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseString OK
     * @throws ApiError
     */
    public createLinkedinPostJob(
        requestBody: CreateLinkedinSearchLinkJobRequest,
    ): Observable<DSResponseString> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/linkedin/post/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param memberId
     * @returns DSResponseLinkedinPostDto OK
     * @throws ApiError
     */
    public getPost(
        memberId: string,
    ): Observable<DSResponseLinkedinPostDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/post/{memberId}',
            path: {
                'memberId': memberId,
            },
        });
    }
    /**
     * @param jobId
     * @param pageIndex
     * @returns DSResponsePageLinkedinPostDto OK
     * @throws ApiError
     */
    public getResults1(
        jobId: string,
        pageIndex?: number,
    ): Observable<DSResponsePageLinkedinPostDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/linkedin/post/results/{jobId}',
            path: {
                'jobId': jobId,
            },
            query: {
                'pageIndex': pageIndex,
            },
        });
    }
}
