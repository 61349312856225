/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';
import type { CreateNegotiationRequest } from '../models/CreateNegotiationRequest';
import type { DSResponseHRNegotiationDto } from '../models/DSResponseHRNegotiationDto';
import type { DSResponseListHRNegotiationDto } from '../models/DSResponseListHRNegotiationDto';
import type { DSResponseNegotiation } from '../models/DSResponseNegotiation';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
@Injectable({
    providedIn: 'root',
})
export class NegotiationControllerService {
    constructor(public readonly http: HttpClient) {}
    /**
     * @param id
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public declineOffer(
        id: number,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/negotiation/offer/decline/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public approveByHrProvider(
        id: number,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/api/v1/processManagement/negotiation/offer/approveByHrProvider/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns DSResponseNegotiation OK
     * @throws ApiError
     */
    public createNegotiation(
        requestBody: CreateNegotiationRequest,
    ): Observable<DSResponseNegotiation> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/api/v1/processManagement/negotiation/offer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param processId
     * @returns DSResponseListHRNegotiationDto OK
     * @throws ApiError
     */
    public getAllByProcessId(
        processId: number,
    ): Observable<DSResponseListHRNegotiationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/negotiation/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
    /**
     * @param processId
     * @returns DSResponseHRNegotiationDto OK
     * @throws ApiError
     */
    public getAllByHrCompanyId(
        processId: number,
    ): Observable<DSResponseHRNegotiationDto> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/api/v1/processManagement/negotiation/received/{processId}',
            path: {
                'processId': processId,
            },
        });
    }
}
