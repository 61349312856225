<div [ngClass]="addMode ? 'card': ''">
    <div class="text-center" *ngIf="!addMode">
        <h3 style="margin-bottom: 0px;">Edit Job Ad Content</h3>
        <p class="mb-5">Adjust job details for public posting without changing the original request.</p>
    </div>
    <div *ngIf="!addMode"><hr /></div>
    <div style="width: 655px; margin-left: auto; margin-right: auto; height: 650px;">
        <form [formGroup]="myForm">
            <div class="form">
                <div class="section section-1">
                    <h3>
                        <span class="required">*</span>Job advertisement

                        <button class="image-button mr-2"
                            pTooltip="Enter the details you’re looking for in a candidate." tooltipPosition="bottom">
                            <img src="../../../assets/images/info.svg" alt="info icon" class="info-icon" />
                        </button>
                    </h3>
                    <div class="mb-0 title">
                        <label><span class="required">*</span>Request title</label>
                        <input formControlName="title" placeholder="Enter Title" [ngClass]="getClassObject('title')" />
                    </div>
                    <div class="input-group mb-0 years-wrapper">
                        <div class="input-max">
                            <div>
                                <label><span class="required">*</span>Min. years of experience</label>
                                <input formControlName="min" placeholder="Min. experience"
                                    [ngClass]="{'border-invalid': !myForm.get('min')?.valid || isMinMaxInvalid()}"
                                    type="number" />
                            </div>
                            <div>
                                <label><span class="required">*</span>Max. years of experience</label>
                                <input formControlName="max" placeholder="Max. experience"
                                    [ngClass]="{'border-invalid':  isMinMaxInvalid()}" type="number" />
                            </div>
                        </div>
                        <div *ngIf="isMinMaxInvalid()" class="error-message">
                            Min years cannot be greater than Max years.
                        </div>
                    </div>
                    <div class="input-group">
                        <label><span class="required">*</span>Keywords</label>
                        <p-chips placeholder="Type your keywords and press enter" formControlName="keywordList"
                            (onRemove)="removeKeyword($event.value)" (onAdd)="onValueAdded($event)" id="chipsInput">
                            <ng-template let-item pTemplate="item">
                                <span class="chip-item">{{ item }}</span>
                                <img src="../../../assets/images/closeIconKeyword.svg" alt="remove" class="remove-icon"
                                    (click)="removeKeyword(item)" /> </ng-template></p-chips>
                    </div>
                    <div class="section section-3">
                        <ng-container *ngFor="let control of section1Dropdowns; let i = index">
                            <div *ngIf="control.control !== 'salaryRange'" class="dropdown-group">
                                <label [for]="'dropdown' + i">
                                    <span class="required">*</span>{{ control.label }}
                                </label>
                                <p-multiSelect [options]="control.array" [formControlName]="control.control"
                                    placeholder="{{ control.placeholder }}" [id]="'dropdown' + i"
                                    (onChange)="onSelect($event, i)">
                                </p-multiSelect>
                            </div>
                        </ng-container>

                    </div>
                    <div *ngFor="let control of section3Textareas; let i = index">
                        <h3>
                          {{control.label}}
                          <span class="optional mr-2" *ngIf="control.optional"
                            >(Optional)</span
                          >
                          <button
                            class="image-button"
                            pTooltip="{{ control.control === 'note' ? 'If you have any specific notes for the HR company or any specific requests from the HR company, you can write them down here.' : 'You can describe to the HR company any specific qualities you’re looking for (e.g. soft skills, etc.).' }}"
                            tooltipPosition="bottom"
                            tooltipStyleClass="custom-tooltip"
                          >
                            <img
                              src="../../../assets/images/info.svg"
                              alt="info icon"
                              class=" info-icon"
                            />
                          </button>
                        </h3>
                        <div class="textarea-group">
                          <label [for]="'textarea' + i">{{control.label}}</label>
                          <textarea
                            [id]="'textarea' + i"
                            [formControlName]="control.control"
                            placeholder="{{control.placeholder}}"
                          ></textarea>
                        </div>
                      </div>

                </div>
                <div class="section section-3" style="margin-top: 44px;">
                    <ng-container *ngFor="let control of section2Dropdowns; let i = index">
                        <div *ngIf="control.control !== 'salaryRange'" class="dropdown-group">
                            <label [for]="'dropdown' + i">
                                <span class="required">*</span>{{ control.label }}
                            </label>
                            <p-multiSelect [options]="control.array" [formControlName]="control.control"
                                placeholder="{{ control.placeholder }}" [id]="'dropdown' + i"
                                (onChange)="onSelect($event, i)">
                            </p-multiSelect>
                        </div>
                    </ng-container>
                    <div class="calendar-wrapper">
                        <div>
                            <label><span class="required">*</span>Deadline</label>
                            <p-calendar [showIcon]="true" inputId="icon" placeholder="Select" formControlName="endDate"
                                [minDate]="todaysDate"></p-calendar>
                        </div>
                    </div>

                    <div class="dropdown-group">
                        <label [for]="'dropdown-salaryRange'">
                            <span class="required mt-2">*</span>Salary range
                        </label>
                        <p-dropdown [options]="getSalaryRangeOptions()" formControlName="salaryRange"
                            placeholder="Select" id="dropdown-salaryRange" class="salary-range-dropdown">
                        </p-dropdown>
                    </div>

                    <div>
                        <label><span class="required">*</span>Number of candidates</label>
                        <input formControlName="numOfCandidates" placeholder="Number of candidates"
                            [ngClass]="{'border-invalid': !myForm.get('min')?.valid || isMinMaxInvalid()}"
                            type="number" />
                    </div>
                    <div *ngIf="addMode">
                        <div *ngFor="let control of section4Textareas; let i = index">
                            <h3>
                              {{control.label}}
                              <span class="optional mr-2" *ngIf="control.optional"
                                >(Optional)</span
                              >
                              <button
                                class="image-button"
                                pTooltip="{{ control.control === 'note' ? 'If you have any specific notes for the HR company or any specific requests from the HR company, you can write them down here.' : 'You can describe to the HR company any specific qualities you’re looking for (e.g. soft skills, etc.).' }}"
                                tooltipPosition="bottom"
                                tooltipStyleClass="custom-tooltip"
                              >
                                <img
                                  src="../../../assets/images/info.svg"
                                  alt="info icon"
                                  class=" info-icon"
                                />
                              </button>
                            </h3>
                            <label [for]="'dropdown-salaryRange'">
                                <span class="required mt-2">*</span>Search company
                            </label>
                            <p-dropdown [options]="getSalaryRangeOptions()" formControlName="salaryRange"
                                placeholder="Select" id="dropdown-salaryRange" class="salary-range-dropdown">
                            </p-dropdown>
                          </div>
                    </div>
                    
                </div>
            </div>
           
        </form>
    </div>
    <div class="mt-4" style="display: flex; justify-content: space-between;">
        <div>
            <button type="button" label="Cancel" class="p-button-cancel" iconPos="left" style="margin-left: 30px;"
                (click)="cancelEdit()" *ngIf="!addMode">
                Cancel
            </button>
        </div>
        <div class="down-buttons">
            <div *ngIf="addMode" style="transform: translate(40px, 30px);">
                <label style="display: flex;">
                  <input type="checkbox" class="input-publish" />
                  <p class="publish">Publish the job anonymously</p>
                </label>
              </div>
            <button type="button" label="Cancel" class="p-button-cancel back  mr-5" iconPos="left"
            (click)="cancelEdit()">
            {{addMode ? 'Cancel' : 'Go Back'}}
        </button>
            <button type="submit" class="p-button-save internal" iconPos="left" (click)="next()">
                {{addMode ? 'Next' : 'Save Changes'}}
            </button>
        </div>
    </div>
</div>