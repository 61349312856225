import { Component, Optional } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProcessUtilsService } from 'src/app/helpers/process-utils.service';
import { SuccessDialogComponent } from '../../negotiation-dialogs/success-dialog/success-dialog.component';
import { JobAdsPreviewDialogComponent } from '../job-ads-preview-dialog/job-ads-preview-dialog.component';

@Component({
  selector: 'app-job-ads-screen-questions',
  templateUrl: './job-ads-screen-questions.component.html',
  styleUrls: ['./job-ads-screen-questions.component.scss']
})
export class JobAdsScreenQuestionsComponent {
  process: any | undefined;
  routeMode: boolean = false;
  constructor(@Optional() public ref: DynamicDialogRef, // Optional injection
  @Optional() public config: DynamicDialogConfig,
    private processUtils: ProcessUtilsService,
    private dialogService: DialogService) {
      if(this.config){
        this.process = this.config.data.process
      }
      else{
        this.process = {
          acceptedHRProvider:{
            name: 'Selected provider',
            webSiteUrl: 'website.com'
          }
        }
        this.routeMode = true;
      }
  }

  questionsLabel = [
    { label: 'Question 1' }, { label: 'Question 2' }];

  responseOptions = [
    { label: 'Yes/No', value: 'yes-no' },
    { label: 'Multiple Choice', value: 'multiple-choice' },
  ];

  idealAnswerOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  selectedResponseType: string = '';
  selectedIdealAnswer: string = '';

  onImageError(event: Event): void {
    (event.target as HTMLImageElement).src = '../assets/images/hr-provider/default-logo.svg';
    console.error('Image failed to load, using fallback.');
  }

  addMoreQuestions() {
    this.questionsLabel.push({ label: `Question ${this.questionsLabel.length + 1}` });
  }

  remove() {
    if (this.questionsLabel.length > 2) {
      this.questionsLabel = this.questionsLabel.slice(0, -1);
    }
  }

  cancelEdit(){
    this.ref.close();
  }

  save(){
    if(this.routeMode){
      const ref = this.dialogService.open(JobAdsPreviewDialogComponent, {
        data: {
          process: this.process
        },
      });
  
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
  
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  
      ref.onClose.subscribe(result=>{
      })
    }
    else{
      this.ref.close();
      const ref = this.dialogService.open(SuccessDialogComponent, {
        data: {
          title: 'Job ad successfully published!',
          paraph: 'Good luck.',
        },
      });
  
      const dialogRef = this.dialogService.dialogComponentRefMap.get(ref);
      const dynamicComponent = dialogRef?.instance as DynamicDialogComponent;
  
      const ariaLabelledBy = dynamicComponent.getAriaLabelledBy();
      dynamicComponent.getAriaLabelledBy = () => ariaLabelledBy;
  
      ref.onClose.subscribe(result=>{
      })
    }
  }
}
