<p-dialog [(visible)]="displayDialog" [style]="{ width: '697px', height: '542px', borderRadius: '25px' }" [modal]="true" [closable]="false"
    header="Define the process">
    <div class="dialog-content">
        <h4 class=" text-center">
            Define the process
        </h4>
        <p class="subtitle  text-center">
            Set up the steps of the hiring process to access the job ad and start reviewing applicants.
        </p>
        <div class="process-steps">
            <div class="step-row" *ngFor="let step of steps; let i = index">
                <p-dropdown [(ngModel)]="steps[i].value" [options]="dropdownOptions"
                    placeholder="Select Step"></p-dropdown>
                <button pButton icon="pi pi-trash" class="delete-btn" (click)="removeStep(i)"></button>
            </div>
            <a (click)="addStep()" class="add-more">+ Add more</a>
        </div>
        <button pButton label="Done" class="done-btn" (click)="saveProcess()"></button>
    </div>
</p-dialog>