<div class="job-dialog" *ngIf="!editMode" style="    padding-left: 20px;
padding-right: 20px;">
    <div class="job-dialog-header">
      <div class="company-info">
        <div>
            <img [src]="process?.acceptedHRProvider?.logoUrl" (error)="onImageError($event)" alt="Logo" />
        </div>
        <div class="company-details ml-2">
          <h2>{{process.acceptedHRProvider?.name}}</h2>
          <p>{{process.acceptedHRProvider?.webSiteUrl}}</p>
        </div>
      </div>
      <div class="edit-button" style="align-items: center; margin-top: 40px;">
        <button
        class="image-button mr-2"
        pTooltip="Edit"
        tooltipPosition="bottom"
        (click)="editMode = true"
      >
        <img src="../../../assets/images/edit.svg" alt="Edit Image" />
      </button>
      </div>
    </div>
    <hr/>
    <div class="job-dialog-body">
      <div class="job-title">
        <h3>Job Title</h3>
        <p >{{ process?.requirement?.name }}</p>
      </div>
      <div class="job-description">
        <h3>Job Description</h3>
        
        <p class="job-desc">
            Lorem ipsum dolor sit amet consectetur. Ac leo velit sed lacus mattis sagittis. Vivamus eleifend turpis consequat convallis. Ipsum elementum sed lacus arcu aliquam porta. Scelerisque sed dis at vestibulum. Egestas sed egestas faucibus amet etiam tellus eu mi eget. Amet diam ac vel odio. Sit arcu ultrices tortor mattis eu. Ullamcorper felis augue adipiscing ullamcorper vitae massa felis quam. Tellus congue dolor consequat non euismod feugiat sed. Est massa neque enim vestibulum at amet consequat arcu tempus. Molestie in ultricies varius blandit diam morbi convallis lacus. Ultrices tempor id sit facilisis tempus nulla massa ridiculus. Netus magna fermentum blandit orci natoque interdum neque egestas quisque. Condimentum quis vestibulum dui ut.
        </p>
      </div>
      <div class="job-details mt-5">
        <div class="left-column">
        
            <ul class="details-list">
                <li>
                  <strong>Experience:</strong>
                  <div>{{ formatExperience(process?.requirement?.minExperience, process?.requirement?.maxExperience) }}</div>
                </li>
                <li>
                  <strong>Language(s):</strong>
                  <div>{{ formatLanguages(process?.requirement?.languages) }}</div>
                </li>
                <li>
                  <strong>Location:</strong>
                  <div>{{process?.requirement?.location}}</div>
                </li>
                <li>
                  <strong>Start-End Date:</strong>
                  <div>{{process?.deadline}}</div>
                </li>
                <li>
                  <strong>Candidates Needed:</strong>
                  <div>{{process?.numberOfHires}}</div>
                </li>
              </ul>
        </div>
        <div class="right-column">
          <ul class="details-list">
            <li><strong>Working Type:</strong><div>{{ formatWorkingTypes(process?.requirement?.workingTypes) }}</div></li>
            <li><strong>Job Type:</strong><div>{{ formatJobTypes(process?.requirement?.jobTypes) }}</div></li>
            <li>
              <strong>Required Capabilities:</strong>
              <div class="skills">
                <ng-container
                  *ngFor="let keyword of process?.requirement?.keywordList"
                >
                  <span>{{ keyword }}</span>
                </ng-container>
              </div>
            </li>
            <li><strong>Salary Range:</strong><div>{{process?.salaryRange}}</div></li>
          </ul>
         
        </div>
      </div>
    </div>
    <div style="float: right;">
        <label style="display: flex;">
          <input type="checkbox" />
          <p class="publish">Publish the job anonymously</p>
        </label>
      </div>
    <div class="job-dialog-footer">
      <button class="cancel-button" (click)="closeDialog()">Cancel</button>
      <button class="next-button" (click)="openDialog()">Next</button>
    </div>
  </div>
  <div *ngIf="editMode">
    <app-job-ads-edit-dialog [processId]="process?.id" (update)="editMode = false"></app-job-ads-edit-dialog>
  </div>
  