<div class="company-details">
    <div class="container-top">
        <div class="company-header-wrapper">
            <img [src]="company?.companyDto?.logoUrl" class="profile-photo" alt="logo" (error)="onImageError($event)" />
            <div>
                <h1>Job Title</h1>
                <p class="profile-title">
                    <a href="{{ company?.companyDto?.webSiteUrl }}">Description</a>
                </p>
            </div>
        </div>
    </div>
    <div class="pl-4 pr-8 pt-4">
        <h1>Applicant overview</h1>
        <div class="card">
            <div style="display: flex;">
                <div>
                    <img [src]="company?.companyDto?.logoUrl" class="profile-photo" alt="logo"
                        (error)="onImageError($event)" />
                </div>
                <div style=" margin-left: 10px;">
                    <h4 style="margin-bottom: 0; margin-top: 10px;">Jane Depp</h4>
                    <p>Full Stack Web Developer I Angular, React, Node, .NET Developer</p>
                </div>
            </div>
            <div style="display: flex;">
                <button class="button reject">Reject</button>
                <button class="button accept">Accept</button>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="wrapper-user-info-left">
            <div class="work-history-container mt-4">
                <h1>About</h1>
                <p>Lorem ipsum dolor sit amet consectetur. Ac leo velit sed lacus mattis sagittis.
                    Vivamus eleifend turpis consequat convallis. Ipsum elementum sed lacus arcu aliquam porta.
                    Scelerisque sed dis at vestibulum. Egestas sed egestas faucibus amet etiam tellus eu mi eget. Amet
                    diam ac vel odio. Sit arcu ultrices tortor mattis eu. Ullamcorper felis augue adipiscing ullamcorper
                    vitae massa felis quam. Tellus congue dolor consequat non euismod feugiat sed. Est massa neque enim
                    vestibulum at amet consequat arcu tempus. Molestie in ultricies varius blandit diam morbi convallis
                    lacus. Ultrices tempor id sit facilisis tempus nulla massa ridiculus. Netus magna fermentum blandit
                    orci</p>
            </div>
            <hr />

            <div>
                <h1>Experience</h1>
                <ul>
                    <ng-container *ngFor="let experience of experiences">
                        <div class="container">
                            <div>
                                <h2>{{ experience.institutionName }}</h2>

                                <ul *ngFor="let titles of experience.titles">
                                    <tr>
                                        <td>
                                            <p>{{ titles.positionTitle }}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <span *ngIf="titles.fromDate || titles.toDate">
                                            {{ titles.fromDate | date : "MMM yyyy" }} -
                                            {{ titles.toDate | date : "MMM yyyy" }} /
                                            {{ titles.duration }}</span>
                                    </tr>

                                    <div class="about">{{ titles.about }}</div>
                                    <br />
                                </ul>
                            </div>
                        </div>
                        <hr />
                    </ng-container>
                </ul>
            </div>
            <div>
                <h1>Screening question</h1>
                <div class="screening-questions">
                    <div class="question-row" *ngFor="let question of questions">
                        <span class="question-text">{{ question.text }}</span>
                        <span class="qualification pl-2">{{ question.qualification }}</span>
                        <span class="ideal-answer">Ideal answer: {{ question.idealAnswer }}</span>
                        <span class="user-answer">{{ question.userAnswer }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper-line">
            <hr />
        </div>
        <div class="wrapper-user-info-right mt-7">
            <div style="margin-top: 30px;">
                <p>Portofolio link</p>
                <p class="text-bold">link.link.ba</p>
            </div>
            <div style="margin-top: 30px;">
                <p>Location</p>
                <p class="text-bold">Mostar, Republic Of Bosnia and Herzegovina</p>
            </div>
            <div style="margin-top: 30px;">
                <p>Required capabilites</p>
                <div style="display: flex;">
                    <p class="types">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                    <p class="types ml-1">Job Type</p>
                </div>
            </div>
            <div style="margin-top: 30px;">
                <p>Language</p>
                <p class="text-bold">Bosnian</p>
                <span>Native or bilingual profiency</span>
            </div>
        </div>
    </div>
</div>