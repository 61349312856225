/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FilterLinkedinPostRequest = {
    firstName?: string;
    lastName?: string;
    authorTitle?: string;
    content?: string;
    likeCount?: string;
    commentCount?: string;
    publishDate?: string;
    jobId?: string;
    pageSize?: number;
    pageIndex?: number;
    sortBy?: string;
    sortDirection?: FilterLinkedinPostRequest.sortDirection;
};
export namespace FilterLinkedinPostRequest {
    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }
}

